<template>
  <div style="background: linear-gradient(180deg, #ECF5FF 0%, #F8FBFF 100%);min-height: 960px;min-width: 850px;position: relative">
    <top ref="tops" :click="click"></top>
    <div class="main">
      <div class="container">
        <div style="display: flex;float: left;padding: 18px;height: 30px">
          <img style="width: 20px;height: 20px;margin-top: 5px" src="../../assets/alert.png" alt="">
          <p style="line-height: 0px;margin-left: 10px;font-size: 15px;color: #4D71FF">
            请稍作等待，检测将在2-5分钟后出结果</p>
        </div>
        <div class="custom-scrollbar" style="height: 630px;overflow-y: scroll;width: 100%;overflow-x: hidden">
          <div style="height: 55px;background-color: #F4F4F4;width: 100%;display: flex;line-height: 55px">
            <div style="width: 25%">标题</div>
            <div style="width: 10%">字数</div>
            <div style="width: 20%">提交时间</div>
            <div style="width: 10%">查重率</div>
            <div style="width: 10%">AI痕迹</div>
            <div style="width: 15%">检测结果</div>
          </div>
          <el-empty style="margin-top: 8vh" :description="description" v-if="booklist.length==0"></el-empty>
          <div v-for="(item,index) in booklist"
               style="height: 55px;width: 100%;display: flex;line-height: 55px;border-bottom: #E7E7E7 1px solid;font-size: 15px">
            <div class="slh" style="width: 25%;color: #4D71FF">{{ item.filename }}</div>
            <div class="slh" style="width: 10%">{{ item.credits}}</div>
            <div class="slh" style="width: 20% ">{{ item.createtime | formatDate }}</div>
            <div class="slh" style="width: 10%;color: #4D71FF" v-if="item.web_check===0">检测中</div>
            <div class="slh" style="width: 10%;color: red" v-if="item.web_check===-1">检测失败</div>
            <div class="slh" style="width: 10%;color: red" v-if="item.web_check===2">检测失败</div>
            <div class="slh" style="width: 10%;color: #1D9461" v-if="item.web_check===1">查重率{{item.web_check_count===0?"0" :item.web_check_count/  item.credits * 100|twoDecimals }}%</div>
            <div class="slh" style="width: 10%;color: #4D71FF" v-if="item.status===0">检测中</div>
            <div class="slh" style="width: 10%;color: red" v-if="item.status===-1">检测失败</div>
            <div class="slh" style="width: 10%;color: red" v-if="item.status===2">检测失败</div>
            <div class="slh" style="width: 10%;color: #1D9461" v-if="item.status===1">AI痕迹{{ item.ai_check_count===0?"0" :item.ai_check_count/item.credits*100|twoDecimals  }}%</div>
            <div class="slh" style="width: 15%;">
              <div class="look" @click="lookDetail(item.id,item.credits,item.filename)" v-if="item.status===1||item.web_check===1">
                查看
              </div>
              <div style="background-color: darkgrey" class="look" v-else>
                查看
              </div>
            </div>
          </div>
        </div>
        <div class="feet">
          <p style="line-height: 0">共计{{ total }}条/第{{ currentPage }}页</p>
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="[10,20,30,50]"
              :page-size="pageSize"
              layout="sizes, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <p class="alert_tip">
      书镜鉴文所标记的AI痕迹字句，皆由书镜鉴文根据AI常用字句自行甄别得出，无论任何稿子都有一定可能被标出AI痕迹，因此书镜鉴文所标记的AI痕迹字句，只提供参考修改作用，无法用于确切证明文稿是由AI创作得出。</p>
  </div>
</template>

<script>
import top from "@/components/Top.vue";
import Login from "@/components/Login.vue";
import {analyzelist} from "@/api/1";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {Login, top},
  data() {
    return {
      description:"检查生成报告中……",
      click: 2,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      booklist: []
    };
  },
  created() {
    // console.log(this.isMobile())
    if( this.isMobile() ){
      this.$router.push("/categoryTel")
    }else {
      this.$router.push("/category")
    }
    this.getList()
    setTimeout(() => {
      this.$refs.tops.reLoad()
    }, 1000);
  },

  methods: {
    isMobile(){
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
          return true; // 是移动设备
        }
      }
      return false; // 是PC设备
    },
    getList() {
      setTimeout(() => {
        if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
          analyzelist({page: this.currentPage, limit: this.pageSize}).then(res => {
            // console.log(res)
            this.booklist = res.data.data
            this.total = res.data.total
            this.description="未生成检测报告"
          }).catch(error => {
            // console.log(error)
            this.$refs.tops.openLogin()
          });
        } else {
          this.$refs.tops.openLogin()
        }
      }, 1000);
    },
    lookDetail(id,credits,filename) {
      this.$router.push({
        path: '/income',
        query: {id: id,credits:credits,filename:filename}
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = Number(val)
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = Number(val)
      // console.log(`当前页: ${val}`);
      this.getList()
    }
  },
};
</script>

<style scoped>
.alert_tip {
  width: 80%;left: 10%;
  text-align: center;
  position: absolute;
  bottom: 20px;
  font-size: 13px;
  color: darkgrey;
}
/* 为滚动区域添加一个类名，如 .custom-scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* 水平滚动条的宽度 */
  height: 10px; /* 垂直滚动条的高度 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条的颜色 */
  border-radius: 5px; /* 滚动条的圆角 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* 滚动条轨道的颜色 */
}

/* 针对Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* 针对IE */
.custom-scrollbar {
  -ms-overflow-style: thin;
}

/deep/ .el-tabs__nav-wrap::after {
  position: static !important;
}

.feet {
  display: flex;
  justify-content: space-between;
  height: 80px;
  position: absolute;
  /*background-color: black;*/
  bottom: 0;
  width: 95%;
  margin-left: 2.5%;
}

.look {
  background: #6598FF;
  border-radius: 4px 4px 4px 4px;
  width: 30%;
  height: 30px;
  margin-top: 13px;
  line-height: 30px;
  color: white;
  min-width: 60px;
  margin-left: 35%;
  cursor: pointer;
}

.slh {
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}

.container {
  position: relative;
  width: 70%;
  min-width: 560px;
  height: 80%;
  margin-left: 15%;
  background: #FFFFFF;
  box-shadow: 1px 1px 10px 5px rgba(209, 227, 244, 0.3);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #D1E1FF;
  min-height: 800px;
}

.main {
  min-width: 800px;
  padding-top: 10px;
  text-align: center;
  /*min-height: 100vh;*/
  width: 100%;
}
</style>