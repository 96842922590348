<template>
  <div class="TopContainer">
    <div style="display: flex">
      <img class="book-font" src="../assets/first/sj-logo.png" alt="">
      <div class="TopContainerItem" v-for="(item,index) in dataList" @click="goTab(index)">
        <div class="TopContainerItemTop" style="color: #4c70fe" v-if="click===index">
          {{item.name}}
        </div>
        <div class="TopContainerItemTop" v-else>
          {{item.name}}
        </div>
        <div class="TopContainerItemBottom" v-if="click===index">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Top",
  data() {
    return {
      nowUser:{},
      click:0,
      dataList:[
        {
          name:"首页"
        },
        {
          name:"文件检测"
        },
        {
          name:"文件导入"
        },
        {
          name:"查重检测"
        },
        {
          name:"检测报告"
        },
        {
          name:"检测结果"
        }
      ]
    };
  },
  methods:{
    openLogin(){
      // this.$refs.login.handleOpen()
      this.$router.push('/register')
    },
    goTab(index){
      this.click=index
      this.$parent.placeChange(index)
    },
    async logOut() {
      const confirmResult = await this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      /*如果用户确认打印confirm,如果用户取消显示cancel*/
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消退出登录!')
      } else {
        this.nowUser = null
        localStorage.setItem("userinfo", null)
      }
      // if (confirmResult !== 'confirm') {
      //   setTimeout(() => {
      //     this.$router.p
      //   }, 1000);
      // }
    },
    reLoad(){
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        this.nowUser=JSON.parse(localStorage.getItem("userinfo"))
      }
    }
  },
  created() {
    this.reLoad()
  },
}
</script>

<style scoped>
.book-font{
  width: 40px;
  height: 40px;
  position: relative;
  top: 12.5px;
  margin-right: 35px;
}
.peopleInfo{
  cursor: pointer;
  position: relative;
  /*min-width: 180px;*/
  display: flex;
  padding-right: 13vw;
  height: 100%;
  /*background-color: #8c939d;*/
}
.peopleInfoP{
  /*position: absolute;*/
  margin-left: 10px;
  line-height: 33px;
}
.peopleImg{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 13px;
}
.TopContainer{
  box-sizing: border-box;
  position: relative;
  justify-content: space-between;
  height: 65px;
  display: flex;
  width: 100%;
  min-width: 850px;
  background-color: #f2f7fe;
  padding-left: 15vw;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 水平偏移 垂直偏移 模糊半径 颜色 */
}
.TopContainerItem{
  width: 110px;
  text-align: center;
  cursor: pointer;
}
.TopContainerItemTop{
  width: 70px;
  line-height: 60px;
  height: 60px;
  text-align: center;
}
.TopContainerItemBottom{
  height: 6px;
  width: 70px;
  background-color: #4c70fe;
}
</style>