<template>
  <div class="all">
    <Change_tel ref="changeTel"></Change_tel>

    <div
        style="top: 50px;width: 95vw;left: 2.5vw;background-color: white;position: absolute;height: 350px;border-radius: 8px">
      <div class="flex smallItem2_content">
        <p class="smallItem2_content_tip1">头像</p>
        <input type="file" @change="onFileChange" style="display: none" ref="fileInput">
        <div class="img_box">
          <img class="img_box_img" :src="nowUser.avatar" alt="">
          <p class="img_box_p" @click="triggerFileUpload">点击上传</p>
        </div>
      </div>
      <div class="flex smallItem2_content">
        <p class="smallItem2_content_tip1">用户昵称</p>
        <input class="smallItem2_content_tip2" style="border: darkgrey solid 1px" :disabled="!disable_A"
               v-if="disable_A" v-model="nowUser.nickname">
        <input class="smallItem2_content_tip2" :disabled="!disable_A" v-else v-model="nowUser.nickname">
        <p class="smallItem2_content_tip3" @click="disable_change(1)">{{ !disable_A ? '点击修改' : '确认' }}</p>
      </div>
      <div class="flex smallItem2_content">
        <p class="smallItem2_content_tip1">用户ID</p>
        <span class="smallItem2_content_tip2" style="width: auto;position: relative;top: 5px;">{{ nowUser.id }}</span>
      </div>
      <div class="flex smallItem2_content">
        <p class="smallItem2_content_tip1">手机号码</p>
        <p v-if="nowUser.mobile" class="smallItem2_content_tip2">{{ nowUser.mobile }}</p>
        <p v-else class="smallItem2_content_tip2">暂未绑定手机号码</p>
        <p v-if="nowUser.mobile" class="smallItem2_content_tip3" @click="changeTelShow">点击换绑</p>
<!--        <p v-else class="smallItem2_content_tip3" >点击绑定</p>-->
      </div>
          <div class="flex smallItem2_content">
            <p class="smallItem2_content_tip1">微信绑定</p>
            <p class="smallItem2_content_tip2">{{nowUser.third.wechat?'已绑定':'未绑定'}}</p>
            <p class="smallItem2_content_tip3" @click="wechatChange(nowUser.third.wechat)">{{nowUser.third.wechat?'解除绑定':'点击绑定'}}</p>
          </div>

    </div>

    <div style="top: 410px;width: 95vw;left: 2.5vw;background-color: white;position: absolute;height: 300px;border-radius: 8px">
      <div class="flex smallItem2_content" v-if="nowUser.is_set_password">
        <p class="smallItem2_content_tip1">旧密码</p>
        <input type="password" class="smallItem2_content_tip2" style="border: darkgrey solid 1px"
               v-model="change_pwd_data.oldpassword"/>
      </div>
      <div class="flex smallItem2_content">
        <p class="smallItem2_content_tip1">新密码</p>
        <input type="password" class="smallItem2_content_tip2" style="border: darkgrey solid 1px"
               v-model="change_pwd_data.newpassword"/>
      </div>
      <div class="flex smallItem2_content">
        <p class="smallItem2_content_tip1">再次确认</p>
        <input type="password" class="smallItem2_content_tip2" style="border: darkgrey solid 1px"
               v-model="change_pwd_data.renewpassword"/>
      </div>
      <div class="save" @click="change_pwd">保存</div>
    </div>
  </div>
</template>

<script>
import {changeavatar, changenickname, changepwd, order_status, unbind, user_info} from "@/api/1";
import axios from "axios";
import Change_tel from "@/components/change_tel1.vue";
import Login from "@/components/Login.vue";
import top from "@/components/Top.vue";
import Change_we from "@/components/change_we.vue";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {Change_tel},
  name: "people_info",
  data() {
    return {
      disable_A: false,
      moneyMoney: 0,
      disable_B: false,
      disable_C: false,
      nowUser: {},
      now_vip_status: {},
      change_pwd_data: {
        oldpassword: '',
        newpassword: '',
        renewpassword: '',
      },
    }
  },
  created() {
    this.reLoad()
  },
  methods: {
    async wechatChange(b) {
      const that = this
      if (!b) {
        this.$refs.changeWe.changeWeShow()
      } else {
        const confirmResult = await this.$confirm('此操作将解除微信绑定, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        /*如果用户确认打印confirm,如果用户取消显示cancel*/
        if (confirmResult !== 'confirm') {
          return that.$message.info('已取消解除微信绑定')
        } else {
          const user = that.nowUser
          user.third.wechat=false
          localStorage.setItem("userinfo",JSON.stringify(user))
          that.nowUser=user
          unbind().then(res => {
            return that.$message.info('已成功解除微信绑定')
          })
        }
      }
    },
    changeTelShow() {
      this.$refs.changeTel.changeTelShow()
    },
    disable_change(index) {
      const that = this
      if (Number(index) === 1) {
        if (that.disable_A) {
          if (that.nowUser.nickname.length === 0 || that.nowUser.nickname.length > 10 || that.nowUser.nickname.includes(' ')) {
            that.$message.error("昵称为字数为1-10，且不包含空格")
          } else {
            changenickname({nickname: that.nowUser.nickname}).then(res => {
              that.disable_A = false
              if (res.data.code === 1) {
                this.$message.success("您已成功修改昵称")
                localStorage.setItem("userinfo", JSON.stringify(that.nowUser))
                // setTimeout(() => {
                //   this.$router.go("/records?current=1")
                // }, 1000);
              } else {
                that.$message.error(res.data.msg)
                that.disable_A = true
              }
            })
          }
        } else {
          that.disable_A = true
        }
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      this.uploadAvatar(file);
    },
    uploadAvatar(file) {
      const that = this
      // console.log(file)
      const formData = new FormData();
      formData.append('file', file);
      formData.append("category", "custom",);
      if (file && file.type.startsWith('image/')) {
        axios.post('https://sj.yundianai.com/api/common/upload', formData, {
          headers: {
            'Token': JSON.parse(localStorage.getItem("userinfo")).token,
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.code === 1) {
            const user = JSON.parse(localStorage.getItem("userinfo"))
            user.avatar = response.data.data.fullurl
            that.nowUser.avatar = response.data.data.fullurl
            localStorage.setItem("userinfo", JSON.stringify(user))
            changeavatar({avatar: that.nowUser.avatar}).then(res => {
              // console.log(res)
              if (res.data.code === 1) {
                that.$message.success("您已成功修改头像")
              } else {
                that.$message.error(res.data.msg)
              }
            })

            // setTimeout(() => {
            //   this.$router.go("/records?current=1")
            //   // this.current=1
            // }, 1000);
          }
        }).catch(error => {
          // console.error('上传失败:', error);
          that.$message.error('上传失败')
        });
      } else {
        that.$message.error("头像只能是文件格式")
      }
    },
    reLoad() {
      const that = this
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        this.nowUser = JSON.parse(localStorage.getItem("userinfo"))
        user_info().then(res => {
          console.log(res.data.data)
        })
        order_status().then(res => {
          // console.log(res.data.data)
          that.now_vip_status = res.data.data
        })
      } else {
        this.$message.error('您已退出登录!')
        setTimeout(function () {
          that.$router.push("/firstTel")
        }, 600);
      }
    },
    triggerFileUpload() {
      this.$refs.fileInput.click(); // 触发文件上传
    },
    change_pwd() {
      if (this.change_pwd_data.newpassword.length >= 6) {
        changepwd(this.change_pwd_data).then(res => {
          // console.log(res)
          if (res.data.code === 1) {
            this.$message.success("密码修改成功，请重新登录")
            localStorage.setItem("userinfo", null)
            setTimeout(() => {
              this.$router.push("/register")
            }, 1000);
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else {
        this.$message.error("新密码的最小长度为6")
      }
    },
  }
}
</script>
<style>
.el-message-box {
  width: 39vh;
}
</style>
<style scoped>
.all {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #D5EBFF 0%, #F0F7FF 15%, #FAFCFF 22%, #FAFCFF 100%);
}

.img_box {
  margin-left: 30px;
  cursor: pointer;
  width: 100px;
}
.save {
  text-align: center;
  width: 20vw;
  height: 35px;
  line-height: 35px;
  margin-bottom: 4vh;
  margin-top: 3vh;
  cursor: pointer;
  color: white;
  margin-left: 40vw;
  background: #4D71FF;
  border-radius: 12px 12px 12px 12px;
}
.img_box_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 20px;
}

.img_box_p {
  line-height: 0px;
  position: relative;
  bottom: 10px;
  color: #ABABAB;
  font-size: 15px;
}

.smallItem2_content {
  line-height: 30px;
  width: 95%;
  left: 2.5%;
  position: relative;
}

.smallItem2_content_tip1 {
  font-size: 15px;
  width: 20%;
  text-align: left;
}

.smallItem2_content_tip2 {
  background-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  height: 38px;
  margin-top: 10px;
  width: 80%;
  text-align: left;
  border: none;
}

.smallItem2_content_tip2:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px transparent;
}

.smallItem2_content_tip3 {
  position: absolute;
  font-size: 15px;
  cursor: pointer;
  right: 0;
  color: #4D71FF;
}

.flex {
  display: flex;
}
</style>