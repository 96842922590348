<template>
  <div class="total_container">
    <top style="position: fixed;z-index: 100" ref="tops" :click="click"></top>
    <img class="big_img t13" src="../../assets/first/t13.png" alt="">
    <img class="big_img t23" src="../../assets/first/t23.png" alt="">
    <img class="big_img t43" src="../../assets/first/t43.png" alt="">
    <div id="box0" class="container_box">
      <img class="sjjw_png" src="../../assets/first/sjjw.png" alt="">
      <p class="container_box_title">一款基于AI大模型的写作助手，可批量检测文章是否带有AI痕迹，是否与其他文稿 有内容重合，并对文章进行分析整理，生成检测报告进行汇总。</p>
      <div  id="box1" class="use_now">
        <div @click="useRightNow">立即使用<i class="el-icon-right"></i></div>

        <img class="small_img t11" src="../../assets/first/t11.png" alt="">
        <img class="small_img t12" src="../../assets/first/t12.png" alt="">
      </div>
      <div class="container_box_1">
        <p class="container_box_1_title">文本<span style="color: #4c70fe">检测</span></p>
        <p class="container_box_1_content">复制黏贴，一键检测</p>
        <p class="container_box_1_tip">直接将文章复制到文本框内一键检测，检测结果可在检测报告中直接查看。</p>
      </div>
    </div>
    <div class="container_box2"  id="box2">
      <img class="small_img t21" src="../../assets/first/t21.png" alt="">
      <img class="small_img t22" src="../../assets/first/t22.png" alt="">
      <div class="container_box_2">
        <p class="container_box_1_title">文件<span style="color: #4c70fe">导入</span></p>
        <p class="container_box_1_content" style="background-color: #9289ef">大量文章，批量检测</p>
        <p class="container_box_1_tip">大量文章的情况下，可用文件形式或压缩包形式批量导入进行批量检测，导入后还可在书镜内进行文本的删除和重新上传等操作，确保文件内容的准确性和便捷度。</p>
      </div>
    </div>

    <div class="container_box3"  id="box3">
      <img class="small_img t31" src="../../assets/first/t5.png" alt="">
      <img class="small_img t32" src="../../assets/first/t52.png" alt="">
      <div class="container_box_3">
        <p class="container_box_1_title">查重<span style="color: #4c70fe">检测</span></p>
        <p class="container_box_1_content" style="background-color: #ee9577">点击标黄字体，展示重合文稿</p>
        <p class="container_box_1_tip">可在检测报告中，点击标黄内容，可查看检测文稿内容与网络上 的哪一份文稿内容重合，并附上对应重合文稿的链接，可直接跳 转调阅重合文稿的完整内容及发布渠道。</p>
      </div>
    </div>
    <div class="container_box2"  id="box4">
      <img class="small_img t31" src="../../assets/first/t32.png" alt="">
      <img class="small_img t32" src="../../assets/first/t33.png" alt="">
      <div class="container_box_3">
        <p class="container_box_1_title">检测<span style="color: #4c70fe">报告</span></p>
        <p class="container_box_1_content" style="background-color: #739dfe">快速分析，精准检测</p>
        <p class="container_box_1_tip">在文章经过检测后，可随时调阅每一份文章的检测报告，其中详细的记录了文章的字数，提交时间，检测状态等，还会将异常的稿件标记，方便查找。</p>
      </div>
    </div>
    <div class="container_box3"  id="box5">
      <img class="small_img t41" src="../../assets/first/t41.png" alt="">
      <img class="small_img t42" src="../../assets/first/t42.png" alt="">
      <div class="container_box_2">
        <p class="container_box_1_title">检测<span style="color: #4c70fe">结果</span></p>
        <div style="display: flex;padding-left: 27%"><p class="container_box_4_content" style="background-color: #85d280">在线编辑</p><p style="background-color: #c37cd6" class="container_box_4_content">文章导出</p></div>
        <p class="container_box_1_tip">可在检测报告中，查看是否有AI痕迹段落，及是否有重合度超标内容，若是对文章内容不满，可直接在检测报告中进行在线编辑，编辑后可将修改后的文章进行导出使用。（AI痕迹标蓝色，查重内容标黄色。）</p>
      </div>
    </div>

  </div>
</template>

<script>
var VueScrollTo = require("vue-scrollto");
import Top from "@/components/Top2.vue";

export default {
  components: {Top},
  data(){
    return{
      click:0
    }
  },
  created() {
    if (this.isMobile()) {
      this.$router.push("/registerTel")
    }else {
      if (localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        this.$router.push("/login")
        this.$message({
          message: '登录成功',
          type: 'success'
        });
      }
    }
  },
  methods:{
    //该方法用于判断是否进入手机端
    isMobile(){
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
          return true; // 是移动设备
        }
      }
      return false; // 是PC设备
    },
    placeChange(index){
        VueScrollTo.scrollTo(document.getElementById("box" + index), 1000, {
          offset: -200,
        });
    },
    useRightNow(){
      this.$router.push("/register")
    }
  }
}
</script>

<style scoped>
.container_box_1_title{
  font-weight: bold;
  font-size: 32px;
  color: #001772;
  line-height: 10px;
}
.container_box_1_content{
  width: 50%;
  margin-left: 25%;
  line-height: 30px;
  margin-top: 40px;
  color: white;
  height: 30px;
  font-size: 13px;
  background: #62BEC8;
  border-radius: 8px;
}.container_box_4_content{
  width: 27%;
  line-height: 30px;
  margin-top: 10px;
  margin-right: 15px;
  color: white;
  height: 30px;
  font-size: 13px;
  background: #62BEC8;
  border-radius: 8px;
}
.container_box_1_tip{
  font-size: 13px;
  text-align: left;
  width: 96%;
  margin-left: 1%;
  line-height: 20px;
  margin-top: 30px;
}
.container_box_2{
  width: 350px;
  position: absolute;
  /*border: 1px solid black;*/
  height: 200px;
  top: 80px;
  left: 150px;
}
.container_box_1{
  width: 350px;
  position: absolute;
  /*border: 1px solid black;*/
  height: 200px;
  top: 380px;
  right: 120px;
}
.container_box_3{
  width: 350px;
  position: absolute;
  /*border: 1px solid black;*/
  height: 200px;
  top: 100px;
  right: 80px;
}
.small_img{
  position: absolute;
  width: 55%;
  height: auto;
}
.t11{
  z-index: 2;
  top: 41%;
  left:5%;
}
.t12{
  z-index: 1;
  left: 5%;
  top: 39%;
}
.t21{
  z-index: 2;
  top: 30px;
  right: 60px;
}
.t22{
  z-index: 1;
  right: 80px;
  top: 0;
}
.t31{
  z-index: 2;
  top: 35px;
  left: 130px;
}
.t32{
  z-index: 1;
  left: 100px;
  top: 0;
  width: 58%;
}.t41{
  z-index: 2;
  top: 50px;
   right: 70px;
}
.t42{
  z-index: 1;
  right: 40px;
  top: -10px;
  width: 60%;
}
.total_container{
  background: linear-gradient(180deg, #ECF5FF 0%, #F8FBFF 100%);
  width:99vw;
  min-width: 1800px;
  min-height: 2700px;
  height: 360vh;
  position: relative;
  overflow: hidden;
  box-sizing: border-box
}
.use_now{
  cursor: pointer;
  width: 10%;
  margin-left: 45%;
  color: white;
  font-size: 18px;
  margin-top: 35px;
  line-height: 45px;
  height: 45px;
  background: linear-gradient( 270deg, #4D71FF 0%, #9D34D6 100%);
  border-radius: 12px 12px 12px 12px;
}
.big_img{
  object-fit: cover;
  position: absolute;
  width: 430px;
  height: auto;
}
.t13{
  right: -100px;
  top: 18%;
}.t23{
  left: 90px;
  top:28%;
}.t43{
  right: 90px;
  top:55%;
}
.container_box_title{
  font-size: 25px;
  width: 70%;
  line-height: 38px;
  margin-left: 15%;
}
.sjjw_png{
  width: 180px;
  height: 45px;
}
.container_box{
  padding-top: 35px;
  position: relative;
  height: 25%;
  /*border: #4b6ffd 1px solid;*/
  margin-top: 70px;
  margin-left: 15%;
  text-align: center;
  width: 70%;
  margin-bottom: 30px;
}.container_box2{
  position: relative;
  height: 16%;
  /*border: #4b6ffd 1px solid;*/
  margin-top: 70px;
  margin-left: 15%;
  text-align: center;
  width: 70%;
  margin-bottom: 30px;
}
.container_box3{
  position: relative;
  height: 16%;
  /*border: #4b6ffd 1px solid;*/
  margin-top: 70px;
  margin-left: 15%;
  text-align: center;
  width: 70%;
  margin-bottom: 30px;
}
</style>