<template>
  <div class="TopContainer">
    <div style="display: flex">
      <div class="TopContainerItem" v-for="(item,index) in dataList" @click="goTab(index)">
        <div class="TopContainerItemTop" style="color: #4c70fe" v-if="click===index">
          {{item.name}}
        </div>
        <div class="TopContainerItemTop" v-else>
          {{item.name}}
        </div>
        <div class="TopContainerItemBottom" v-if="click===index">
      </div>
    </div>
    </div>
    <div class="dialog-box3" v-if="showGao">
      <img class="gongao1" src="../assets/gongao1.png" alt="">
      <img class="gongao2" src="../assets/gongao2.png" alt="">
      <div class="dialog-box3-item">
        <div class="iTab">
          <div @click="tabClick=1" :class="[ tabClick===1?'tabClick': 'iTab_item' ]">最新信息</div>
          <div @click="tabClick=2" :class="[ tabClick===2?'tabClick': 'iTab_item' ]">历史信息</div>
        </div>
        <div class="iTitle" v-if="tabClick===1">
          {{newAlert.title}}
        </div>
        <div class="iIfo" v-if="tabClick===1">
          <pre class="pre">{{newAlert.content}}</pre>
        </div>
        <div class="iTitle" v-if="tabClick===3">
          {{nowAlert.title}}
        </div>
        <div class="iIfo" v-if="tabClick===3">
          <pre class="pre">{{nowAlert.content}}</pre>
        </div>
        <div class="iIfo2" v-if="tabClick===2">
          <div v-for="(item,index) in alertList">
            <div class="iIfo2-item-p">
              {{ item.createtime|formatDate }}
            </div>
            <div class="iIfo2-item" @click="alertChoose(item)">
              <p class="iIfo2-item-title">{{item.title}}</p>
              <p class="iIfo2-item-info">{{item.content}}</p>
            </div>
          </div>

        </div>
        <div class="iKnow" v-if="tabClick===1" @click="showGao=false">
          知道了
        </div>
        <div class="iKnow" v-if="tabClick===3" @click="tabClick=2">
          知道了
        </div>
      </div>
    </div>
    <div class="dialog-box2" v-if="showHelp">
      <p class="p1">请联系客服</p>
      <img src="../assets/ewm1.png" alt="">
      <p class="p2">微信扫描二维码添加客服</p>
    </div>
    <div class="dialog-box" v-if="showDialog">
      <img :src="nowUser.avatar" class="dialog-avatar" alt="">
      <p class="dialog-nickname">{{ nowUser.nickname }}</p>
      <p class="dialog-id">ID:{{ nowUser.id }}</p>
      <p class="dialog-remain_word_count">我的积分:{{ now_vip_status.remain_word_count }}</p>
      <div class="vip-box vip0-bg" @click="beVip(1)" v-if="now_vip_status.top_level<=0">
        点击开通会员
      </div>
      <div class="vip-box vip1-bg" v-if="now_vip_status.top_level===1">
        初级
      </div>
      <div class="vip-box vip2-bg" v-if="now_vip_status.top_level===2">
        中级
      </div>
      <div class="vip-box vip3-bg" v-if="now_vip_status.top_level===3">
        高级
      </div>
      <div class="vip-box vip4-bg" v-if="now_vip_status.top_level===4">
        旗舰版
      </div>
      <img class="win-png" src="../assets/win.png" alt="" v-if="now_vip_status.top_level>0">
      <img v-if="now_vip_status.top_level===1" class="tip-vip" src="../assets/tip-vip1.png" alt="">
      <img v-if="now_vip_status.top_level===2" class="tip-vip" src="../assets/tip-vip2.png" alt="">
      <img v-if="now_vip_status.top_level===3" class="tip-vip" src="../assets/tip-vip3.png" alt="">
      <img v-if="now_vip_status.top_level===4" class="tip-vip" src="../assets/tip-vip4.png" alt="">
      <p class="now_level" v-if="now_vip_status.top_level>0">当前等级</p>
      <p class="end_date_at" v-if="now_vip_status.top_level>0">会员有效期至{{now_vip_status.end_at|formatDate}}
      <span style="margin-left: 10px;color: #1D2087;cursor: pointer" @click="beVip(2)">
        升级套餐》
      </span>
      </p>
      <div class="back_log" @click="logOut">
        退出登录
      </div>
    </div>
    <div class="dialog-shadow" v-if="showDialog" @click="showDialog=false">
    </div>
    <div class="dialog-shadow" v-if="showHelp" @click="showHelp=false"></div>
    <div class="dialog-shadow2" v-if="showGao" @click="showGao=false">
    </div>
    <img class="helpCenter" style="margin-right: 30px" src="../assets/helpCenter.png" alt="" @click="showHelp=true">
    <img class="gongao" style="margin-right: 30px" src="../assets/gongao.png" alt="" @click="showGao=true">
    <div v-if="nowUser!==''" class="peopleInfo" @click="showDialog=true">
      <img v-if="nowUser.avatar" class="peopleImg" :src="nowUser.avatar" alt="">
      <p class="peopleInfoP">{{ nowUser.nickname }}</p>
    </div>

    <div v-else class="peopleInfo"  @click="openLogin">
      <img class="peopleImg" src="../assets/people.png" alt="">
      <p class="peopleInfoP">请登录</p>
    </div>
  </div>
</template>

<script>

import {order_status, site_notice} from "@/api/1";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "top",
  props: {
    click: Number
  },
  data() {
    return {
      alertList:[],
      newAlert: {},
      nowAlert: {},
      tabClick:1,
      activeName:"first",
      showDialog:false,
      showHelp:false,
      showGao:false,
      nowUser:'',
      now_vip_status:{},
      dataList:[
        {
          name:"文本检测"
        },
        {
          name:"文件导入"
        },
        {
          name:"检测报告"
        },
        {
          name:"个人中心"
        }
      ]
    };
  },

  methods:{
    alertChoose(item){
      this.tabClick=3
      this.nowAlert=item
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    openLogin(){
      // this.$refs.login.handleOpen()
      this.$router.push('/first')
    },
    goTab(index){
        // console.log(index)
        // this.$parent
      if (Number(index)===0){
        this.$router.push('/login')
      }
      if (Number(index)===1){
        this.$router.push('/home')
      }
      if (Number(index)===2){
        this.$router.push('/category')
      }
      if (Number(index)===3) {
        this.$router.push('/records')
      }
    },
    beVip(index){
      if (index===2){
        localStorage.setItem("up",'isTrue')
      }
      if(this.$route.path==="/records"){
        this.$router.go(0)
      }else {
        this.$router.push('/records')
      }
    },
    async logOut() {
      const that =this
      const confirmResult = await this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      /*如果用户确认打印confirm,如果用户取消显示cancel*/
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消退出登录!')
      } else {
        this.nowUser = null
        localStorage.setItem("userinfo", null)
        localStorage.setItem("gao",null)
        this.$message.success('您已退出登录!')
        setTimeout(function() {
          that.$router.push("/first")
        }, 600);
      }
    },
    reLoad(){
      const that =this
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        this.nowUser=JSON.parse(localStorage.getItem("userinfo"))
        order_status().then(res => {
          // console.log(res.data.data)
          that.now_vip_status = res.data.data
        })
      }else {
        this.$message.success('您已退出登录!')
        setTimeout(function() {
          that.$router.push("/first")
        }, 600);
      }
    }
  },
  created() {
    this.reLoad()
    site_notice({}).then(res => {
      console.log("total",res.data.data.rows)
      this.alertList=res.data.data.rows
    })
    site_notice({type:"new"}).then(res => {
      console.log("new",res.data.data.rows[0])
      this.newAlert=res.data.data.rows[0]
      if (this.newAlert.is_popup===1&&localStorage.getItem("gao")!=="yes"){
        this.showGao=true
        localStorage.setItem("gao","yes")
      }
    })
  },
}
</script>

<style scoped>
.iIfo2-item-title{
  line-height: 20px;
  font-size: 15px;
  margin: 0;
  text-align: left;
}
.iIfo2-item-info{
  line-height: 25px;
  font-size: 15px;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
}
.iTab{
  display: flex;
  top: 0;
  left: 0;
  justify-content: space-between;
  line-height: 3vh;
  width: 170px;
}
.iTab_item{
  width: 75px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  color: #989899;
}
.tabClick{
  width: 75px;
  text-align: center;
  border-bottom: #4c70fe solid 3px;
  font-size: 15px;
  color: #4c70fe;
  cursor: pointer;
}
.helpCenter{
  cursor: pointer;
  position: absolute;
  height: 48px;
  width: 48px;
  top: 10px;
  left: 76.5%;
}
.gongao{
  cursor: pointer;
  position: absolute;
  height: 48px;
  width: 48px;
  top: 10px;
  left: 73.5%;
}
.end_date_at{
  position: absolute;
  font-size: 13px;
  top: 215px;
  line-height: 0;
  left: 50px;
  color: #5F72BE;
}
.back_log{
  cursor: pointer;
  height: 50px;
  /*background-color: black;*/
  position: absolute;
  border-top: #dcddde 1px solid;
  line-height: 60px;
  text-align:center;
  left: 10%;
  top: 270px;
  width: 80%;
}
.dialog-avatar{
  position: absolute;
  top: 30px;
  left: 50px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.dialog-nickname{
  line-height: 0;
  position: absolute;
  top: 30px;
  left: 135px;
}
.dialog-id{
  line-height: 0;
  position: absolute;
  top: 60px;
  left: 135px;
}
.vip-box{
  width: 80%;
  position: absolute;
  left: 10%;
  height: 115px;
  background-color: #d9deeb;
  top: 130px;
  border-radius: 12px;
}
.vip0-bg{
  text-align: center;
  cursor: pointer;
  line-height: 115px;
  font-size: 22px;
  color: #909cc4;
}
.vip1-bg{
  background: linear-gradient( 90deg, #C4E2F5 0%, #D8EEFF 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.win-png{
  position: absolute;
  top: 135px;
  width: 80px;
  right: 60px;
}
.tip-vip{
  position: absolute;
  top: 130px;
  width: 80px;
  left: 10%;
}
.now_level{
  position: absolute;
  top: 127px;
  width: 70px;
  left: 12%;
  color: #fff;
  font-size: 15px;
  line-height: 5px;
}
.vip2-bg {
  background: linear-gradient(90deg, #d2dfec 0%, #c7cde8 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.vip3-bg{
  background: linear-gradient( 90deg, #b3d2ed 0%, #a7baf4 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.vip4-bg{
  background: linear-gradient( 90deg, #a0abe4 0%, #D8EEFF 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.dialog-remain_word_count{
  line-height: 0;
  position: absolute;
  top: 90px;
  left: 135px;
  color: #971717;
}
.dialog-box{
  position: absolute;
  z-index: 10;
  right: 30px;
  top: 80px;
  width: 391px;
  height: 335px;
  background: linear-gradient( 180deg, #BCD2FF 0%, #D4E8FF 12%, #F4FBFF 40%, #F4FBFF 100%);
  box-shadow: 0 8px 16px 6px rgba(138,141,171,0.25);
}
.dialog-shadow{
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}
.dialog-shadow2 {
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(1,1,1,0.2);
}
.dialog-box2{
  text-align: center;
  position: absolute;
  z-index: 10;
  right: 9vw;
  top: 80px;
  width: 350px;
  height: 320px;
  background: linear-gradient( 180deg, #A0FDEB 0%, #CAFFF3 15%, #EBFBFF 33%, #F3FFFE 100%);
  box-shadow: 0 8px 16px 6px rgba(138,141,171,0.25);
}.dialog-box3{
   text-align: center;
   position: absolute;
   z-index: 10;
   left: 24vw;
   top: 80px;
   width: 50%;
  min-width: 600px;
   height: 65vh;
  min-height: 600px;
  background-color: white;
   box-shadow: 0 8px 16px 6px rgba(138,141,171,0.25);
}
 .dialog-box3-item{
    position: absolute;
   width: 90%;
   left: 5%;
   top: 5%;
   /*border: #1D2087 1px solid;*/
   height: 90%;
 }
 .gongao1{
   position: absolute;
   width: 30%;
   height: auto;
   z-index: 1;
   right: 0;
   top: 0;
 }
  .gongao2{
    position: absolute;
    width: 14%;
    height: auto;
    z-index: 3;
    right: 2vw;
    top: 1vw;
  }
  .iKnow{
    cursor: pointer;
    width: 100px;
    height: 38px;
    border-radius: 8px;
    text-align: center;
    color: white;
    font-size: 15px;
    line-height: 38px;
    right: 0;
    position: absolute;
    bottom: 0;
    background: #6598FF;
  }
  .iTitle{
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    top: 4.8vh;
    font-size: 20px;
    color: #4c70fe;
    position: absolute;
    margin: 0;
    text-align: left;
  }
  .iIfo{
    width: 100%;
    height: 70%;
    top: 10vh;
    padding: 20px;
    overflow-x: hidden;
    box-sizing: border-box;
    overflow-y: scroll;
    z-index: 2;
    position: absolute;
    background: rgb(249, 250, 254);
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(232, 243, 255, 1), rgba(233, 241, 255, 1)) 1 1;
  }
  .iIfo2-item-p{
    line-height: 35px;
    text-align: left;
    margin: 0 0 0 20px;
    color: #8c939d;
  }
  .iIfo2-item{
    cursor: pointer;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 110px;
    background: rgb(249, 250, 254);
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(232, 243, 255, 1), rgba(233, 241, 255, 1)) 1 1;
  }
  .iIfo2{
    width: 100%;
    height: 85%;
    top: 6vh;
    overflow-x: hidden;
    box-sizing: border-box;
    overflow-y: scroll;
    z-index: 2;
    position: absolute;
  }
  .pre{
    font-size: 15px;
    text-align: left;
    line-height: 25px;
    margin: 0;
    white-space: pre-wrap;			/* 保留空白符序列，并正常进行换行 */
    white-space: -moz-pre-wrap;		/* 兼容火狐浏览器 */
    white-space: -o-pre-wrap;			/* 兼容opera浏览器 */
    word-wrap: break-word;			/* 允许字母、url地址换行 */
  }
.dialog-box2 .p1 {
  height: 30px;
  font-size: 18px;
  color: #1D9461;
  /*font-weight: bold;*/
  line-height: 30px;
}
.dialog-box2 .p2 {
  width: 80%;
  margin-left: 10%;
  height: 33px;
  line-height: 33px;
  background: linear-gradient( 90deg, #75F09C 0%, #8AFAF3 100%);
  border-radius: 12px 12px 12px 12px;
}
.book-font{
  width: 40px;
  height: 20px;
  position: relative;
  top: 20px;
  margin-right: 35px;
}
.peopleInfo{
  cursor: pointer;
  position: absolute;
  left: 80%;
  /*min-width: 180px;*/
  display: flex;
  /*padding-right: 250px;*/
  height: 100%;
  /*background-color: #8c939d;*/
}
.peopleInfoP{
  /*position: absolute;*/
  line-height: 33px;
}
.peopleImg{
  margin-right: 12px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 13px;
}
.TopContainer{
  box-sizing: border-box;
  position: relative;
  justify-content: space-between;
  height: 65px;
  display: flex;
  width: 100%;
  min-width: 850px;
  background-color: #f2f7fe;
  padding-left: 15vw;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 水平偏移 垂直偏移 模糊半径 颜色 */
}
.TopContainerItem{
  width: 110px;
  text-align: center;
  cursor: pointer;
}
.TopContainerItemTop{
  width: 70px;
  line-height: 60px;
  height: 60px;
  text-align: center;
}
.TopContainerItemBottom{
  height: 6px;
  width: 70px;
  background-color: #4c70fe;
}
</style>