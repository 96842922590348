import Vue from 'vue'
// 导入vue路由
import Router from 'vue-router'
// 以下是导入页面
import login from './views/login/index.vue'
import loginTel from './views/login/index_tel.vue'
import home from './views/home/index.vue'
import homeTel from './views/home/index_tel.vue'
import firstpage from './views/firstpage/index.vue'
import first from './views/first/first.vue'
import register from './views/register/register.vue'
import registerTel from './views/register/register_tel.vue'
import income from './views/income/index.vue'
import incomeTel from './views/income/index_tel.vue'
import category from './views/category/index.vue'
import categoryTel from './views/category/index_tel.vue'
import records from './views/records/index.vue'
import beVip from './views/records/telBox/beVip.vue'
import recordsTel from './views/records/index_tel.vue'
import czjl from './views/records/telBox/czjl.vue'
import people_info from './views/records/telBox/people_info.vue'
import xfjl from './views/records/telBox/xfjl.vue'
import qyjkh from './views/records/telBox/qyjkh.vue'

// 全局应用路由
Vue.use(Router)

// 路由实现函数
const router = new Router({
    routes: [{
            path: '/',
            redirect: "first"
        },
        {
            path: '/register',
            component: register
        },
        {
            path: '/registerTel',
            component: registerTel
        },
        {
            path: '/login',
            component: login
        },
        {
            path: '/loginTel',
            component: loginTel
        },
        {
            path: '/home',
            component: home,
        },
        {
            path: '/homeTel',
            component: homeTel,
        },
        {
            path: '/category',
            component: category,
        },
        {
            path: '/categoryTel',
            component: categoryTel,
        },
        {
            path: '/records',
            component: records,
        },
        {
            path: '/recordsTel',
            component: recordsTel,
        },
        {
            path: '/czjl',
            component: czjl,
        },
        {
            path: '/xfjl',
            component: xfjl,
        },
        {
            path: '/qyjkh',
            component: qyjkh,
        },
        {
            path: '/people_info',
            component: people_info,
        },
        {
            path: '/beVip',
            component: beVip,
        },
        {
            path: '/income',
            component: income,
        },
        {
            path: '/incomeTel',
            component: incomeTel,
        },
        {
            path: '/firstpage',
            component: firstpage,
        },
        {
            path: '/first',
            component: first,
        },
    ]
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 为路由对象，添加before 导航守卫
router.beforeEach((to, from, next) => {
    document.title = "书镜鉴文";
    next();
})

export default router