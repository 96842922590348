<template>
  <div class="total">
    <div class="head">
      <div class="top-item" @click="goBack" style="cursor: pointer">
        <i class="el-icon-arrow-left"></i>
        <p>返回上级列表</p>
      </div>
      <div class="top-item" style="margin-left: 27.5%">
        <p>编辑模式</p>
<!--        <i class="el-icon-arrow-down"></i>-->
      </div>
    </div>
    <div class="tip_box" style="padding-left: 2%">
      <div class="tip-item">
        <img class="img1" src="../../assets/old.png" alt="">
        <p>原文</p>
      </div>
      <div class="tip-item" style="justify-content: center">
        <!--        <div class="color-box blue"></div>-->
        <img class="color-box" src="../../assets/henji.png" alt="">
        <p style="margin-right: 25px">AI痕迹</p>
        <!--        <div class="color-box red"></div>-->
        <img class="color-box" src="../../assets/chahchon.png" alt="">
        <p>查重</p>
      </div>
      <div class="tip-item" style="justify-content: end">
        <img @click="load_png" class="img2" src="../../assets/load.png" alt="">
        <div @click="goEdit" class="editBtn">保存</div>
      </div>
    </div>
    <div class="detail" @scroll="scroll">
      <div id="editable" class="detail_item" v-html="test" contenteditable="true" @input="changeText">

      </div>
      <!--      <div class="detail_item">-->
      <!--        <div v-html="test"></div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import {analyzeDetail, content_export, get_content, save_content} from "@/api/1";
import axios from "axios";

export default {
  data() {
    return {
      test: '<span></span>',
      content: ''
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.filename = this.$route.query.filename;
    // console.log(this.id)
    this.getDetail()
  },
  methods: {
    changeText() {
      let editableDiv = document.getElementById('editable');
      let editable = editableDiv.innerHTML; // 或者使用 editableDiv.innerHTML 如果你需要HTML标签
      this.content = editable
    },
    getDetail() {
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        get_content(this.id).then(res => {
        this.content = res.data.data
        // console.log(res.data.data)
        this.test = '<pre style="font-size: 15px;line-height: 20px;white-space: pre-wrap;word-wrap: break-word;overflow-wrap: break-word;width: 100%">' + res.data.data + '</pre>'
        // for (let i = 0; i < res.data.data.ai_check.length; i++) {
        //   // console.log(res.data.data.ai_check[i][2])
        //   this.test = this.test.replace(res.data.data.ai_check[i][2], '<span style="color:#6396fd;">' + res.data.data.ai_check[i][2] + '</span>');
        // }
      })}else{
        this.$router.push("/first")
      }
    },
    async load_png() {
      this.$http({
        method: "get",
        url: '/analyze/content_export?log_id='+this.id,
        responseType: 'blob',
        headers: {
          'Token': JSON.parse(localStorage.getItem("userinfo")).token
        },
      }).then((res) => {
        //这里res.data是返回的blob对象
        let type
        // if (this.filename.includes(".docx")){
          type='application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'
        // }
        // if (this.filename.includes(".doc")){
        //   type='application/msword;charset=utf-8'
        // }
        // if (this.filename.includes(".txt")){
        //   type='text/plain;charset=utf-8'
        // }
        var blob = new Blob([res.data], {type: type});
        //application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型
        var contentDisposition = res.headers['content-disposition'];
        //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        var patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
        var filename = this.filename;
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.style.display = 'none';
        downloadElement.href = href;
        downloadElement.download = filename; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      })

    },
    exportData(res) {
      let href = window.URL.createObjectURL(new Blob([res]));
      // console.log(href)
      let downloadElement = document.createElement('a');
      downloadElement.href = href;
      document.body.appendChild(downloadElement);

      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    goBack() {
      this.$router.go(-1)
    },
    goEdit() {
      const contentWithinPreTag = this.content.replace(/<pre.*?>|<\/pre>/gi, '');
      save_content({log_id: this.id, content: contentWithinPreTag}).then(res => {
        if (res.data.code === 1) {
          this.$message.success('修改成功')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    scroll(e) {
      // console.log(e)
    }
  },
}
;
</script>


<style scoped>
[contenteditable] {
  outline: 0px solid transparent;
}

.tip_box {
  min-width: 1000px;
  height: 50px;
  display: flex;
}

.total {
  height: 100vh;
  width: 100vw;
  background-color: #f9f9fa;
  min-width: 1000px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  /*scrollbar-width: none;*/
  height: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(147, 147, 153, 0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.detail {
  /*display: flex;*/
  margin-left: 28%;
  width: 45%;
  /*min-width: 1000px;*/
  height: 88vh;
  box-shadow: 1px 1px 10px 5px rgba(213, 213, 213, 0.3);
  border-radius: 0 0 0 0;
  border: 1px solid #D1E0FF;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 500px;
  float: left;
  /*flex-shrink: 0;*/
}

.detail_item {
  padding: 20px;
  box-sizing: border-box;
  overflow-x: scroll;
  width: 99.85%;
  float: left;
  /*flex-shrink: 0;*/
  height: auto;
  min-height: 88vh;
  background: #FFFFFF;
  box-shadow: 1px 1px 10px 5px rgba(213, 213, 213, 0.3);
  border-radius: 0 0 0 0;
  border: 1px solid #D1E0FF;
  border-right: none;
}

.top-item {
  display: flex;
  width: 20%;
  font-size: 18px;
  margin-left: 10px;
  line-height: 15px;
  /*justify-content: space-around;*/
}

.tip-item {
  display: flex;
  width: 32%;
  font-size: 15px;
  margin-left: 10px;
  line-height: 23px;
  /*justify-content: space-around;*/
}

.el-icon-arrow-left {
  margin-top: 12px;
  font-size: 26px;
  margin-right: 5px;
}

.el-icon-arrow-down {
  margin-top: 12px;
  font-size: 26px;
  margin-left: 5px;
}

.head {
  box-sizing: border-box;
  position: relative;
  /*justify-content: space-between;*/
  height: 50px;
  display: flex;
  width: 100%;
  min-width: 1000px;
  background: #FBFBFB;
  /*padding-left: 15vw;*/
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 水平偏移 垂直偏移 模糊半径 颜色 */
}

.editBtn {
  background-color: #6497fe;
  color: white;
  height: 30px;
  margin-top: 15px;
  width: 80px;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
  cursor: pointer;
}

.color-box {
  height: 18px;
  width: 20px;
  margin-top: 17px;
  margin-right: 5px;
}

.blue {
  background-color: #6497fe;
}

.red {
  background-color: #f75e5d;
}

.img1 {
  height: 15px;
  width: 20px;
  margin-top: 19px;
  margin-right: 5px;
}

.img2 {
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin-top: 16px;
  margin-right: 20px;
}
</style>