<template>
  <div class="TopContainer">
    <div style="display: flex">
      <div class="TopContainerItem" v-for="(item,index) in dataList" @click="goTab(index)">
        <img v-if="index===0&&click===0" src="../assets/feet/wbjc_ac.png" class="TopContainerItemBottom"  alt="">
        <img v-if="index===0&&click!==0" src="../assets/feet/wbjc.png" class="TopContainerItemBottom"  alt="">
        <img v-if="index===1&&click===1" src="../assets/feet/wjdr_ac.png" class="TopContainerItemBottom"  alt="">
        <img v-if="index===1&&click!==1" src="../assets/feet/wjdr.png" class="TopContainerItemBottom"  alt="">
        <img v-if="index===2&&click===2" src="../assets/feet/jcbg_ac.png" class="TopContainerItemBottom"  alt="">
        <img v-if="index===2&&click!==2" src="../assets/feet/jcbg.png" class="TopContainerItemBottom"  alt="">
        <img v-if="index===3&&click==3" src="../assets/feet/grzx_ac.png" class="TopContainerItemBottom"  alt="">
        <img v-if="index===3&&click!==3" src="../assets/feet/grzx.png" class="TopContainerItemBottom"  alt="">
        <div class="TopContainerItemTop" style="color: #4c70fe" v-if="click===index">
          {{item.name}}
        </div>
        <div class="TopContainerItemTop" v-else>
          {{item.name}}
        </div>
      </div>
    </div>
    <div class="dialog-box2" v-if="showHelp">
      <p class="p1">请联系客服</p>
      <img src="../assets/ewm1.png" alt="">
      <p class="p2">微信扫描二维码添加客服</p>
    </div>
    <div class="dialog-box" v-if="showDialog">
      <img :src="nowUser.avatar" class="dialog-avatar" alt="">
      <p class="dialog-nickname">{{ nowUser.nickname }}</p>
      <p class="dialog-id">ID:{{ nowUser.id }}</p>
      <p class="dialog-remain_word_count">我的积分:{{ now_vip_status.remain_word_count }}</p>
      <div class="vip-box vip0-bg" @click="beVip(1)" v-if="now_vip_status.top_level<=0">
        点击开通会员
      </div>
      <div class="vip-box vip1-bg" v-if="now_vip_status.top_level===1">
        初级
      </div>
      <div class="vip-box vip2-bg" v-if="now_vip_status.top_level===2">
        中级
      </div>
      <div class="vip-box vip3-bg" v-if="now_vip_status.top_level===3">
        高级
      </div>
      <div class="vip-box vip4-bg" v-if="now_vip_status.top_level===4">
        旗舰版
      </div>
      <img class="win-png" src="../assets/win.png" alt="" v-if="now_vip_status.top_level>0">
      <img v-if="now_vip_status.top_level===1" class="tip-vip" src="../assets/tip-vip1.png" alt="">
      <img v-if="now_vip_status.top_level===2" class="tip-vip" src="../assets/tip-vip2.png" alt="">
      <img v-if="now_vip_status.top_level===3" class="tip-vip" src="../assets/tip-vip3.png" alt="">
      <img v-if="now_vip_status.top_level===4" class="tip-vip" src="../assets/tip-vip4.png" alt="">
      <p class="now_level" v-if="now_vip_status.top_level>0">当前等级</p>
      <p class="end_date_at" v-if="now_vip_status.top_level>0">会员有效期至{{now_vip_status.end_at|formatDate}}
        <span style="margin-left: 10px;color: #1D2087;cursor: pointer" @click="beVip(2)">
        升级套餐》
      </span>
      </p>
      <div class="back_log" @click="logOut">
        退出登录
      </div>
    </div>
    <div class="dialog-shadow" v-if="showDialog" @click="showDialog=false">
    </div>
    <div class="dialog-shadow" v-if="showHelp" @click="showHelp=false">
    </div>
<!--    <img class="helpCenter" style="margin-right: 30px" src="../assets/helpCenter.png" alt="" @click="showHelp=true">-->
<!--    <div v-if="nowUser!==''" class="peopleInfo" @click="showDialog=true">-->
<!--      <img v-if="nowUser.avatar" class="peopleImg" :src="nowUser.avatar" alt="">-->
<!--      <p class="peopleInfoP">{{ nowUser.nickname }}</p>-->
<!--    </div>-->

<!--    <div v-else class="peopleInfo"  @click="openLogin">-->
<!--      <img class="peopleImg" src="../assets/people.png" alt="">-->
<!--      <p class="peopleInfoP">请登录</p>-->
<!--    </div>-->
  </div>
</template>

<script>

import {order_status} from "@/api/1";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "top",
  props: {
    click: Number
  },
  data() {
    return {
      showDialog:false,
      showHelp:false,
      nowUser:'',
      now_vip_status:{},
      dataList:[
        {
          name:"文本检测"
        },
        {
          name:"文件导入"
        },
        {
          name:"检测报告"
        },
        {
          name:"个人中心"
        }
      ]
    };
  },
  methods:{
    openLogin(){
      // this.$refs.login.handleOpen()
      this.$router.push('/first')
    },
    goTab(index){
      // console.log(index)
      // this.$parent
      if (Number(index)===0){
        this.$router.push('/login')
      }
      if (Number(index)===1){
        this.$router.push('/home')
      }
      if (Number(index)===2){
        this.$router.push('/category')
      }
      if (Number(index)===3) {
        this.$router.push('/records')
      }
    },
    beVip(index){
      if (index===2){
        localStorage.setItem("up",'isTrue')
      }
      if(this.$route.path==="/records"){
        this.$router.go(0)
      }else {
        this.$router.push('/records')
      }
    },
    async logOut() {
      const that =this
      const confirmResult = await this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      /*如果用户确认打印confirm,如果用户取消显示cancel*/
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消退出登录!')
      } else {
        this.nowUser = null
        localStorage.setItem("userinfo", null)
        this.$message.success('您已退出登录!')
        setTimeout(function() {
          that.$router.push("/first")
        }, 600);
      }
    },
    reLoad(){
      const that =this
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        this.nowUser=JSON.parse(localStorage.getItem("userinfo"))
        order_status().then(res => {
          // console.log(res.data.data)
          that.now_vip_status = res.data.data
        })
      }else {
        this.$message.success('您已退出登录!')
        setTimeout(function() {
          that.$router.push("/first")
        }, 600);
      }
    }
  },
  created() {
    this.reLoad()
  },
}
</script>

<style scoped>
.helpCenter{
  cursor: pointer;
  position: absolute;
  height: 48px;
  width: 48px;
  top: 10px;
  left: 76%;
}
.end_date_at{
  position: absolute;
  font-size: 13px;
  top: 215px;
  line-height: 0;
  left: 50px;
  color: #5F72BE;
}
.back_log{
  cursor: pointer;
  height: 50px;
  /*background-color: black;*/
  position: absolute;
  border-top: #dcddde 1px solid;
  line-height: 60px;
  text-align:center;
  left: 10%;
  top: 270px;
  width: 80%;
}
.dialog-avatar{
  position: absolute;
  top: 30px;
  left: 50px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.dialog-nickname{
  line-height: 0;
  position: absolute;
  top: 30px;
  left: 135px;
}
.dialog-id{
  line-height: 0;
  position: absolute;
  top: 60px;
  left: 135px;
}
.vip-box{
  width: 80%;
  position: absolute;
  left: 10%;
  height: 115px;
  background-color: #d9deeb;
  top: 130px;
  border-radius: 12px;
}
.vip0-bg{
  text-align: center;
  cursor: pointer;
  line-height: 115px;
  font-size: 22px;
  color: #909cc4;
}
.vip1-bg{
  background: linear-gradient( 90deg, #C4E2F5 0%, #D8EEFF 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.win-png{
  position: absolute;
  top: 135px;
  width: 80px;
  right: 60px;
}
.tip-vip{
  position: absolute;
  top: 130px;
  width: 80px;
  left: 10%;
}
.now_level{
  position: absolute;
  top: 127px;
  width: 70px;
  left: 12%;
  color: #fff;
  font-size: 15px;
  line-height: 5px;
}
.vip2-bg {
  background: linear-gradient(90deg, #d2dfec 0%, #c7cde8 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.vip3-bg{
  background: linear-gradient( 90deg, #b3d2ed 0%, #a7baf4 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.vip4-bg{
  background: linear-gradient( 90deg, #a0abe4 0%, #D8EEFF 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.dialog-remain_word_count{
  line-height: 0;
  position: absolute;
  top: 90px;
  left: 135px;
  color: #971717;
}
.dialog-box{
  position: absolute;
  z-index: 10;
  right: 30px;
  top: 80px;
  width: 391px;
  height: 335px;
  background: linear-gradient( 180deg, #BCD2FF 0%, #D4E8FF 12%, #F4FBFF 40%, #F4FBFF 100%);
  box-shadow: 0 8px 16px 6px rgba(138,141,171,0.25);
}
.dialog-shadow{
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100vh;
}.dialog-box2{
   text-align: center;
   position: absolute;
   z-index: 10;
   right: 9vw;
   top: 80px;
   width: 350px;
   height: 320px;
   background: linear-gradient( 180deg, #A0FDEB 0%, #CAFFF3 15%, #EBFBFF 33%, #F3FFFE 100%);
   box-shadow: 0 8px 16px 6px rgba(138,141,171,0.25);
 }
.dialog-box2 .p1 {
  height: 30px;
  font-size: 18px;
  color: #1D9461;
  /*font-weight: bold;*/
  line-height: 30px;
}
.dialog-box2 .p2 {
  width: 80%;
  margin-left: 10%;
  height: 33px;
  line-height: 33px;
  background: linear-gradient( 90deg, #75F09C 0%, #8AFAF3 100%);
  border-radius: 12px 12px 12px 12px;
}
.book-font{
  width: 40px;
  height: 20px;
  position: relative;
  top: 20px;
  margin-right: 35px;
}
.peopleInfo{
  cursor: pointer;
  position: absolute;
  left: 80%;
  /*min-width: 180px;*/
  display: flex;
  /*padding-right: 250px;*/
  height: 100%;

  /*background-color: #8c939d;*/
}
.peopleInfoP{
  /*position: absolute;*/
  line-height: 33px;
}
.peopleImg{
  margin-right: 12px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 13px;
}
.TopContainer{
  box-sizing: border-box;
  position: fixed;
  left: 0;
  bottom: 0;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0 -10px 10px 0 rgba(224,223,223,0.25);
  display: flex;
  height: 8.3vh;
  width: 100vw;
}
.TopContainerItem{
  /*background-color: #1D9461;*/
  width: 25vw;
  text-align: center;
  cursor: pointer;
}
.TopContainerItemTop{
  line-height: 1.5vh;
  margin-top: 0.5vh;
  /*border-right: 1px solid grey;*/
  font-size: 14px;
  height: 6vh;
  text-align: center;
}
.TopContainerItemBottom{
  height: 3vh;
  width: 3.2vh;
  margin-top: 1.3vh;
  /*background-color: #4c70fe;*/
}
</style>