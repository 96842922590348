<template>
  <div class="modal" v-if="show">
    <div class="modal-content" @click="showFalse">
    </div>

    <div class="modal-body">
      <div class="modal-header">
        <p class="modal-title">请扫描下方二维码绑定微信</p>
      </div>
      <slot>
        <div id="qrcodeImg" style="position: absolute;top:50%;left:50%;transform: translate(-50%, -50%)"></div>
      </slot>
    </div>
  </div>
</template>
<script>

import {bind_qrcode, bind_status} from "@/api/1";
import QRCode from "qrcodejs2";

export default {
  name: 'Modal',
  data() {
    return {
      show:false,
      oQRCode:''
    };
  },
  created() {

  },
  methods: {
    qrcode(url) {  // 前端根据 URL 生成微信支付二维码
      // document.getElementById('qrcodeImg').innerHTML = ''
      this.oQRCode = new QRCode('qrcodeImg', {
        width: 150,
        height: 150,
        text: url,
        colorDark: '#000',
        colorLight: '#fff'
      });
    },
    showFalse(){
      this.show=false
      document.getElementById('qrcodeImg').innerHTML = ''
      clearTimeout(this.intervalId)
    },
    changeWeShow(){
      const that = this
      this.show=true
      bind_qrcode().then(res => {
        // console.log(res)
        that.qrcode(res.data.data.url);
        that.intervalId = setInterval(function () {
          bind_status().then(async response => {
            // console.log(response)
            if (response.data.data.is_bind) {
              clearTimeout(that.intervalId)
              that.show=false
              that.$parent.bind()
            }
          })
        }, 2000)
      })
    }
  }
}
</script>

<style scoped>
.disabled-style{
  position:absolute;
  top: 20%;
  height: 60%;
  line-height: 0;
  right: 20px;
}
.class_A{
  color: white;
  background-color: #7BC8E4;
}
.primaryBtn{
    width: 40%;
  margin-left: 30%;
  margin-top: 30px;
  background: linear-gradient( 128deg, #5FACFF 0%, #6C81FF 100%);
}
.modal-title{
  text-align: center;
  width: 100%;
  font-size: 22px;
  font-weight: bolder;
}
.modal {
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.modal-body{
  width: 26vw;
  left: 42vw;
  min-width: 300px;
  height: 330px;
  position: absolute;
  top: 250px;
  background-color: white;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  font-size: 24px;
  font-weight: bold;
  color: #aaaaaa;
}
</style>