<template>
  <div style="width: 180px" id="lottie"></div>
</template>
<script>
import animationData from "@/assets/lottie/car-loading2-data.json";
import lottie from "lottie-web";
export default {
  data() {
    return {};
  },
  mounted() {
    this.svgFun();
  },
  methods: {
    svgFun() {
      let params = {
        container: document.getElementById("lottie"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData,
      };
      let anim;
      anim = lottie.loadAnimation(params);
    },
  },
};
</script>
