import { render, staticRenderFns } from "./register.vue?vue&type=template&id=118ca0ca&scoped=true"
import script from "./register.vue?vue&type=script&lang=js"
export * from "./register.vue?vue&type=script&lang=js"
import style0 from "./register.vue?vue&type=style&index=0&id=118ca0ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "118ca0ca",
  null
  
)

export default component.exports