<template>
  <div style="background: linear-gradient( 180deg, #D5EBFF 0%, #F0F7FF 15%, #F5F5F5 22%, #FFFFFF 100%);width: 100vw;height: 100vh">
<!--    <img src="../../assets/helpCenter.png" class="helpCenter" alt="">-->
    <div class="dialog-box">
      <img :src="nowUser.avatar" class="dialog-avatar" alt="">
      <p class="dialog-nickname">{{ nowUser.nickname }}</p>
      <p class="dialog-id">ID:{{ nowUser.id }}</p>
      <div class="dialog-remain_word_count">
        <p>积分</p>
        <p>{{ now_vip_status.remain_word_count }}</p>
      </div>
      <div class="vip-box vip0-bg" @click="beVip()" v-if="now_vip_status.top_level<=0">
        点击开通会员
      </div>
      <div class="vip-box vip1-bg" v-if="now_vip_status.top_level===1">
        初级
      </div>
      <div class="vip-box vip2-bg" v-if="now_vip_status.top_level===2">
        中级
      </div>
      <div class="vip-box vip3-bg" v-if="now_vip_status.top_level===3">
        高级
      </div>
      <div class="vip-box vip4-bg" v-if="now_vip_status.top_level===4">
        旗舰版
      </div>
      <img class="win-png" src="../../assets/win.png" alt="" v-if="now_vip_status.top_level>0">
      <img v-if="now_vip_status.top_level===1" class="tip-vip" src="../../assets/tip-vip1.png" alt="">
      <img v-if="now_vip_status.top_level===2" class="tip-vip" src="../../assets/tip-vip2.png" alt="">
      <img v-if="now_vip_status.top_level===3" class="tip-vip" src="../../assets/tip-vip3.png" alt="">
      <img v-if="now_vip_status.top_level===4" class="tip-vip" src="../../assets/tip-vip4.png" alt="">
      <p class="now_level" v-if="now_vip_status.top_level>0">当前等级</p>
      <p class="end_date_at" v-if="now_vip_status.top_level>0">会员有效期至{{now_vip_status.end_at|formatDate}}
      </p>
      <div class="myServe">
        <p class="myServe_p">我的服务</p>
        <div style="display: flex;height: 70px">
          <div class="myServe_item" @click="beVip(0)">
            <img class="myServe_item_img" src="../../assets/tel_person/vip.png" alt="">
            <p class="myServe_item_p">会员充值</p>
          </div>
          <div class="myServe_item"  @click="beVip(1)">
            <img class="myServe_item_img" src="../../assets/tel_person/info.png" alt="">
            <p class="myServe_item_p">个人资料</p>
          </div>
          <div class="myServe_item" @click="beVip(2)" >
            <img class="myServe_item_img" src="../../assets/tel_person/money.png" alt="">
            <p class="myServe_item_p">充值记录</p>
          </div>
          <div class="myServe_item" @click="beVip(3)" >
            <img class="myServe_item_img" src="../../assets/tel_person/use_money.png" alt="">
            <p class="myServe_item_p">消费记录</p>
          </div>
        </div>
      </div>
      <div class="back_log" @click="historyGao=true">
        历史公告
        <i style="font-size: 25px;color: #8c939d;line-height: 55px" class="el-icon-arrow-right"></i>
      </div>
      <div class="back_log2" @click="logOut">
        退出登录
        <i style="font-size: 25px;color: #8c939d;line-height: 55px" class="el-icon-arrow-right"></i>
      </div>
      <TelFeet :click="3"></TelFeet>
    </div>
    <div class="dialog-shadow2" v-if="historyGao" @click="historyGao=false">
    </div>
    <div class="dialog-box3" v-if="historyGao">
      <img class="gongao1" src="../../assets/gongao1.png" alt="">
      <img class="gongao2" src="../../assets/gongao2.png" alt="">
      <div class="dialog-box3-item">
        <div class="iTab">
          <div @click="tabClick=1" :class="[ tabClick===1?'tabClick': 'iTab_item' ]">最新信息</div>
          <div @click="tabClick=2" :class="[ tabClick===2?'tabClick': 'iTab_item' ]">历史信息</div>
        </div>
        <div class="iTitle" v-if="tabClick===1">
          {{newAlert.title}}
        </div>
        <div class="iIfo" v-if="tabClick===1">
          <pre class="pre">{{newAlert.content}}</pre>
        </div>
        <div class="iTitle" v-if="tabClick===3">
          {{nowAlert.title}}
        </div>
        <div class="iIfo" v-if="tabClick===3">
          <pre class="pre">{{nowAlert.content}}</pre>
        </div>
        <div class="iIfo2" v-if="tabClick===2">
          <div v-for="(item,index) in alertList">
            <div class="iIfo2-item-p">
              {{ item.createtime|formatDate }}
            </div>
            <div class="iIfo2-item" @click="alertChoose(item)">
              <p class="iIfo2-item-title">{{item.title}}</p>
              <p class="iIfo2-item-info">{{item.content}}</p>
            </div>
          </div>

        </div>
        <div class="iKnow" v-if="tabClick===1" @click="historyGao=false">
          知道了
        </div>
        <div class="iKnow" v-if="tabClick===3" @click="tabClick=2">
          知道了
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {order_status, site_notice} from "@/api/1";
import TelFeet from "@/components/tel_feet.vue";
export default {
  components: {TelFeet},
  data() {
    return {
      alertList:[],
      newAlert: {},
      nowAlert: {},
      tabClick:1,
      now_vip_status:{},
      historyGao:true,
      nowUser:'',
    };
  },
  created() {
    this.reLoad()
    site_notice({}).then(res => {
      console.log("total",res.data.data.rows)
      this.alertList=res.data.data.rows
    })
    site_notice({type:"new"}).then(res => {
      console.log("new",res.data.data.rows[0])
      this.newAlert=res.data.data.rows[0]
      if (this.newAlert.is_popup===1&&localStorage.getItem("gao")!=="yes"){
        this.showGao=true
        localStorage.setItem("gao","yes")
      }
    })
  },

  methods: {
    historyGao(){
      this.historyGao=true
    },
    beVip(index){
      if (Number(index)===0){
        this.$router.push("/beVip")
      }
      if (Number(index)===1){
        this.$router.push("/people_info")
      }
      if (Number(index)===2){
        this.$router.push("/czjl")
      }
      if (Number(index)===3){
        this.$router.push("/xfjl")
      }
    },
    reLoad(){
      const that =this
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        this.nowUser=JSON.parse(localStorage.getItem("userinfo"))
        order_status().then(res => {
          // console.log(res.data.data)
          that.now_vip_status = res.data.data
        })
      }else {
        this.$message.success('您已退出登录!')
        setTimeout(function() {
          that.$router.push("/first")
        }, 600);
      }
    },
    alertChoose(item){
      this.tabClick=3
      this.nowAlert=item
    },
    async logOut() {
      const that =this
      const confirmResult = await this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      /*如果用户确认打印confirm,如果用户取消显示cancel*/
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消退出登录!')
      } else {
        this.nowUser = null
        localStorage.setItem("userinfo", null)
        localStorage.setItem("gao",null)
        this.$message.success('您已退出登录!')
        setTimeout(function() {
          that.$router.push("/first")
        }, 600);
      }
    },
  },
};
</script>

<style>
.el-message-box {
  width: 39vh;
}
</style>
<style scoped>

.iIfo2-item-p{
  line-height: 35px;
  text-align: left;
  margin: 0 0 0 20px;
  color: #8c939d;
}
.iIfo2-item{
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 110px;
  background: rgb(249, 250, 254);
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(232, 243, 255, 1), rgba(233, 241, 255, 1)) 1 1;
}
.iIfo2-item-title{
  line-height: 20px;
  font-size: 15px;
  margin: 0;
  text-align: left;
}
.iIfo2-item-info{
  line-height: 25px;
  font-size: 15px;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
}
.pre{
  font-size: 15px;
  text-align: left;
  line-height: 25px;
  margin: 0;
  white-space: pre-wrap;			/* 保留空白符序列，并正常进行换行 */
  white-space: -moz-pre-wrap;		/* 兼容火狐浏览器 */
  white-space: -o-pre-wrap;			/* 兼容opera浏览器 */
  word-wrap: break-word;			/* 允许字母、url地址换行 */
}
.iTab{
  display: flex;
  top: 0;
  left: 0;
  justify-content: space-between;
  line-height: 3vh;
  width: 170px;
}
.iTab_item{
  width: 75px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  color: #989899;
}
.tabClick{
  width: 75px;
  text-align: center;
  border-bottom: #4c70fe solid 3px;
  font-size: 15px;
  color: #4c70fe;
  cursor: pointer;
}
.dialog-box3{
  text-align: center;
  position: absolute;
  z-index: 200;
  left: 5vw;
  top: 10vh;
  width: 90vw;
  height: 80vh;
  background-color: white;
  box-shadow: 0 8px 16px 6px rgba(138,141,171,0.25);
}
.dialog-box3-item{
  position: absolute;
  width: 90%;
  left: 5%;
  top: 5%;
  /*border: #1D2087 1px solid;*/
  height: 90%;
}
.gongao1{
  position: absolute;
  width: 40vw;
  height: auto;
  z-index: 1;
  right: 0;
  top: 0;
}
.gongao2{
  position: absolute;
  width: 25vw;
  height: auto;
  z-index: 3;
  right: 2vw;
  top: 1vw;
}
.iKnow{
  cursor: pointer;
  width: 100px;
  height: 38px;
  border-radius: 8px;
  text-align: center;
  color: white;
  font-size: 15px;
  line-height: 38px;
  right: 0;
  position: absolute;
  bottom: 0;
  background: #6598FF;
}
.iTitle{
  width: 100%;
  height: 5vh;
  line-height: 5vh;
  top: 4.8vh;
  font-size: 20px;
  color: #4c70fe;
  position: absolute;
  margin: 0;
  text-align: left;
}
.dialog-shadow2 {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(1,1,1,0.2);
}
.iIfo{
  width: 100%;
  height: 70%;
  top: 10vh;
  padding: 20px;
  overflow-x: hidden;
  box-sizing: border-box;
  overflow-y: scroll;
  z-index: 2;
  position: absolute;
  background: rgb(249, 250, 254);
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(232, 243, 255, 1), rgba(233, 241, 255, 1)) 1 1;
}
.myServe_item{
  width: 25%;
  height: 100%;
  text-align: center;
}
.myServe_p{
  margin-bottom: 0;
  margin-top: 0;
}
.myServe_item_img{
  width: 50px;
  height: 50px;
}
.myServe_item_p{
  margin: 0;
  line-height: 0px;
  font-size: 14px;
}
.helpCenter{
  cursor: pointer;
  position: absolute;
  height: 10vw;
  width: 10vw;
  top: 20px;
  left: 83vw;
}
.end_date_at{
  position: absolute;
  font-size: 13px;
  top: 215px;
  line-height: 0;
  left: 4vw;
  color: #5F72BE;
}
.myServe{
  cursor: pointer;
  height: 160px;
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 0 rgba(234,234,234,0.25);
  /*border-top: #dcddde 1px solid;*/
  line-height: 55px;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  left: 2.5vw;
  top: 260px;
  width: 95vw;
}
.back_log{
  cursor: pointer;
  height: 55px;
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 0 rgba(234,234,234,0.25);
  border-bottom: 1px solid rgba(234,234,234,1);
  /*border-top: #dcddde 1px solid;*/
  line-height: 55px;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  left: 2.5vw;
  /*top: 260px;*/
  top: 430px;
  width: 95vw;
}.back_log2{
  cursor: pointer;
  height: 55px;
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 0 rgba(234,234,234,0.25);
  /*border-top: #dcddde 1px solid;*/
  line-height: 55px;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  left: 2.5vw;
  /*top: 260px;*/
  top: 485px;
  width: 95vw;
}
.dialog-avatar{
  position: absolute;
  top: 30px;
  left: 50px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.dialog-nickname{
  line-height: 0;
  position: absolute;
  top: 30px;
  left: 135px;
}
.dialog-id{
  line-height: 0;
  position: absolute;
  top: 60px;
  left: 135px;
}
.vip-box{
  width: 95vw;
  position: absolute;
  left: 2.5vw;
  height: 115px;
  background-color: #d9deeb;
  top: 130px;
  border-radius: 12px;
}
.vip0-bg{
  text-align: center;
  cursor: pointer;
  line-height: 115px;
  font-size: 22px;
  color: #909cc4;
}
.vip1-bg{
  background: linear-gradient( 90deg, #C4E2F5 0%, #D8EEFF 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.win-png{
  position: absolute;
  top: 135px;
  width: 80px;
  right: 60px;
}
.tip-vip{
  position: absolute;
  top: 130px;
  width: 80px;
  text-align: center;
  left: 2.5vw;
}
.now_level{
  position: absolute;
  top: 127px;
  width: 70px;
  left: 15px;
  color: #fff;
  font-size: 15px;
  line-height: 5px;
}
.vip2-bg {
  background: linear-gradient(90deg, #d2dfec 0%, #c7cde8 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.vip3-bg{
  background: linear-gradient( 90deg, #b3d2ed 0%, #a7baf4 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.vip4-bg{
  background: linear-gradient( 90deg, #a0abe4 0%, #D8EEFF 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.dialog-remain_word_count{
  position: absolute;
  top: 30px;
  width: 20vw;
  height: 60px;
  right: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 8px;
  color: #971717;
  background: linear-gradient( 180deg, #FFCEA7 0%, #FFE2A4 100%);
  border-radius: 8px;
}
.dialog-box{
  position: absolute;
  z-index: 10;
  right: 0;
  top: 50px;
  width: 100vw;
  height: 70vh;
}

.dialog-box2 .p1 {
  height: 30px;
  font-size: 18px;
  color: #1D9461;
  /*font-weight: bold;*/
  line-height: 30px;
}
.dialog-box2 .p2 {
  width: 80%;
  margin-left: 10%;
  height: 33px;
  line-height: 33px;
  background: linear-gradient( 90deg, #75F09C 0%, #8AFAF3 100%);
  border-radius: 12px 12px 12px 12px;
}

</style>