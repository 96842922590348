<template>
  <el-dialog
      title=" "
      :visible.sync="dialogVisible"
      width="450px"
      :before-close="handleClose">

  </el-dialog>
</template>

<script>
import {login} from "@/api/1";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  data() {
    return {
      countdown:0,
      dialogVisible:false,
      account:'',
      password:'',
      loginType:1
    };
  },
  methods:{
    login() {
      login({account: this.account, password: this.password}).then(res => {
        // localStorage.setItem("userinfo", JSON.stringify(res.data.data.userinfo))
        // console.log(JSON.parse(localStorage.getItem("userinfo")))
        // this.nowUser = JSON.parse(localStorage.getItem("userinfo"))
        this.$router.go(0)
        this.$message({
          message: '登录成功',
          type: 'success'
        });
      }).catch(error => {
        this.$message({
          message: '账号密码输入有误',
          type: 'error'
        });
      });
    },
    getVerificationCode() {
      // 调用API获取验证码的逻辑

      // 开始倒计时
      this.startCountdown(60); // 假设倒计时60秒
    },
    startCountdown(seconds) {
      this.countdown = seconds;
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    handleOpen(){
      this.dialogVisible=true
    },
    handleClose(){
      this.dialogVisible=false
    }
  }
}
</script>

<style scoped>
.login{
  width: 36%;
  height: 42px;
  margin-left: 32%;
  background: #6598FF;
  border-radius: 8px 8px 8px 8px;
  color: white;
  margin-top: 37px;
}
.input{
  width: 70%;
  margin-left: 15%;
  height: 35px;
  margin-top: 20px;
  border-radius: 0px 0px 0px 0px;
  border: 2px solid;
  background-color: white;
  border-image: linear-gradient(180deg, rgba(87, 124, 255, 1), rgba(66, 154, 236, 1)) 2 2;
}
.align{
  text-align: center;
}
</style>