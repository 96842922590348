<template>
  <div class="dialog-wrapper">
    <img src="../../assets/login-bg.png" alt="" class="login-bg">
    <div class="top-div">
      <div class="top-div-item">
        <img src="../../assets/book-logo.png" alt="" class="book-logo">
        <img src="../../assets/book-font.png" alt="" class="book-font">
      </div>
      <div class="top-div-item" @click="goFirst">
        <img class="goFirst" src="../../assets/go1.png" alt="">

      </div>
    </div>
    <p class="please_login">请先登录使用</p>
    <div class="dialog" style="height: 500px;width: 850px;display: flex">
      <!--        <div style="width: 20vw;position: absolute;right: 5vw;bottom: 13vh"><el-divider>其它方式登录</el-divider></div>-->
      <div style="width: 350px;background-color: #6497fe;position: relative">
        <img src="../../assets/login-main.png" alt="" class="login_main">
        <img src="../../assets/font2.png" class="login_main_title" alt="">
      </div>
      <div style="width: 500px;position: relative;">
        <div class="dialog-content">
          <div v-if="loginType===1">
            <div class="align" style="margin-top: 10px">
              <img style="width: 230px;height: 30px" src="../../assets/PSWLogin.png" alt="">
            </div>
            <input class="input" v-model="account" type="text">
            <input class="input" v-model="password" type="password" @keyup.enter="login">
            <p class="forget_PSW"></p>
            <el-button class="login" @click="login" style="margin-top: 37px">登录</el-button>
            <div class="agreement">
              <el-checkbox v-model="ischeck" size="large"/>
              我已阅读并同意
              <a href="https://api.xz.yundianai.com/agreement/qwe.html" target="_blank">用户协议</a>
              和
              <a href="https://api.xz.yundianai.com/agreement/qwe.html" target="_blank">隐私政策</a>

            </div>
            <div class="other">
              <span></span>
              其他登录方式
              <span></span>
            </div>
            <div style="display: flex;width: 30%;justify-content: space-around;margin-left: 35%">
              <div style="text-align: center;cursor: pointer" @click="loginType=2">
                <img style="width: 15px;height: 20px" src="../../assets/Tel.png" alt="">
                <p style="width: 100%;line-height: 0;font-size: 13px">手机号</p>
              </div>
              <div style="text-align: center;cursor: pointer" @click="login_get_qrcode()">
                <img style="width: 20px;height: 20px" src="../../assets/wechat.png" alt="">
                <p style="width: 100%;line-height: 0;font-size: 13px">微信</p>
              </div>
            </div>
          </div>
          <div v-if="loginType===2">
            <div class="align" style="margin-top: 10px">
              <img style="width: 230px;height: 30px" src="../../assets/yzmLogin.png" alt="">
            </div>
            <input class="input" v-model="phone" type="text">
            <div style="position: relative">
              <input class="input" v-model="captcha" type="text" @keyup.enter="login">

              <button @click="getVerificationCode" :disabled="countdown > 0"
                      style="position: absolute;top: 33px;right: 18%;color: #3669CC;cursor: pointer;background-color: rgba(0,0,0,0);border: none">
                {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}
              </button>
            </div>

            <el-button class="login" @click="login" style="margin-top: 50px">登录</el-button>
            <div class="agreement">
              <el-checkbox v-model="ischeck" size="large"/>
              我已阅读并同意
              <a href="https://api.xz.yundianai.com/agreement/qwe.html" target="_blank">用户协议</a>
              和
              <a href="https://api.xz.yundianai.com/agreement/qwe.html" target="_blank">隐私政策</a>

            </div>
            <div class="other">
              <span></span>
              其他登录方式
              <span></span>
            </div>
            <div style="display: flex;width: 30%;justify-content: space-around;margin-left: 35%">
              <div style="text-align: center;cursor: pointer" @click="login_get_qrcode()">
                <img style="width: 20px;height: 20px" src="../../assets/wechat.png" alt="">
                <p style="width: 100%;line-height: 0;font-size: 13px">微信</p>
              </div>
              <div style="text-align: center;cursor: pointer" @click="loginType=1">
                <img style="width: 20px;height: 20px" src="../../assets/yzm.png" alt="">
                <p style="width: 100%;line-height: 0;font-size: 13px">密码</p>
              </div>
            </div>
          </div>
          <div v-if="loginType===3">
            <div class="align" style="margin-top: 10px">
              <img style="width: 230px;height: 30px" src="../../assets/loginTitle.png" alt="">

            </div>
            <div class="align" style="margin-top: 15px">
              <div id="qrcodeImg" style="margin-left: 180px"></div>
              <!--              <img style="width: 150px;height: 150px" :src="url" alt="">-->
            </div>
            <div class="align" style="margin-top: 30px">
              <span style="color: black">请使用微信扫码二维码进行登录注册</span>
            </div>
            <div class="agreement">
              <el-checkbox v-model="ischeck" size="large"/>
              我已阅读并同意
              <a href="https://sj.yundianai.com/yhxy.html" target="_blank">用户协议</a>
              和
              <a href="https://sj.yundianai.com/ysxy.html" target="_blank">隐私政策</a>
            </div>
            <div class="other">
              <span></span>
              其他登录方式
              <span></span>
            </div>
            <div style="display: flex;width: 30%;justify-content: space-around;margin-left: 35%">
              <div style="text-align: center;cursor: pointer" @click="loginType=2">
                <img style="width: 15px;height: 20px" src="../../assets/Tel.png" alt="">
                <p style="width: 100%;line-height: 0;font-size: 13px">手机号</p>
              </div>
              <div style="text-align: center;cursor: pointer" @click="loginType=1">
                <img style="width: 20px;height: 20px" src="../../assets/yzm.png" alt="">
                <p style="width: 100%;line-height: 0;font-size: 13px">密码</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {login, login_qrcode, login_status, mobilelogin, order_pay_result, send} from "@/api/1";
import QRCode from "qrcodejs2";


export default {
  data() {
    return {

      ischeck: false,
      url: '',
      countdown: 0,
      dialogVisible: false,
      account: '',
      password: '',
      phone: '',
      captcha: '',
      loginType: 3,
      intervalId: '',
      uuid: ''
    };
  },
  created() {
    // console.log(this.isMobile())
    if (this.isMobile()) {
      this.$router.push("/registerTel")
    } else {
      this.$router.push("/register")
      if (localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        this.$router.push("/login")
        this.$message({
          message: '登录成功',
          type: 'success'
        });
      }
    }
    this.login_get_qrcode()
  },

  methods: {
    //该方法用于判断是否进入手机端
    isMobile() {
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
          return true; // 是移动设备
        }
      }
      return false; // 是PC设备
    },
    goFirst() {
      this.$router.push('/first')
    },
    login_get_qrcode() {
      const that = this
      clearTimeout(that.intervalId)
      this.loginType = 3
      login_qrcode().then(res => {
        this.qrcode(res.data.data.url);
        this.intervalId = setInterval(function () {
          // if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
          login_status({uuid: res.data.data.uuid}).then(async response => {
            if (response.data.data.is_scanned) {
              clearTimeout(that.intervalId)
              if (response.data.data.user_id !== 0) {
                if (!that.ischeck) {
                  const confirmResult = await that.$confirm('是否同意用户协议和隐私协议?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).catch(err => err)
                  /*如果用户确认打印confirm,如果用户取消显示cancel*/
                  if (confirmResult !== 'confirm') {
                    that.login_get_qrcode()
                  } else {
                    that.ischeck = true
                    localStorage.setItem("userinfo", JSON.stringify(response.data.data.userinfo))
                    await that.$router.push("/login")
                    that.$message({
                      message: '登录成功',
                      type: 'success'
                    });
                  }
                } else {
                  that.ischeck = true
                  localStorage.setItem("userinfo", JSON.stringify(response.data.data.userinfo))
                  await that.$router.push("/login")
                  that.$message({
                    message: '登录成功',
                    type: 'success'
                  });
                }
              } else {
                // console.log(res)
                that.uuid = res.data.data.uuid
                that.$message.info("请先绑定手机号")
                that.loginType = 2
              }
            }
            // console.log(res.data.data.is_scanned)
            if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
              clearTimeout(that.intervalId)
            }
          })
        }, 2000)
        // }else {
        //     clearInterval(intervalId)
        //   }
      })
    },
    qrcode(url) {  // 前端根据 URL 生成微信支付二维码
      document.getElementById('qrcodeImg').innerHTML = ''
      this.oQRCode = new QRCode('qrcodeImg', {
        width: 150,
        height: 150,
        text: url,
        colorDark: '#000',
        colorLight: '#fff'
      });
    },
    async login() {
      const that = this
      if (!that.ischeck) {
        const confirmResult = await this.$confirm('是否同意用户协议和隐私协议?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        /*如果用户确认打印confirm,如果用户取消显示cancel*/
        if (confirmResult !== 'confirm') {
          return
        } else {
          that.ischeck = true
          return
        }
      }

      if (this.loginType === 1) {
        login({account: this.account, password: this.password}).then(res => {
          localStorage.setItem("userinfo", JSON.stringify(res.data.data.userinfo))
          // console.log(JSON.parse(localStorage.getItem("userinfo")))
          this.nowUser = JSON.parse(localStorage.getItem("userinfo"))
          this.$router.push("/login")
          this.$message({
            message: '登录成功',
            type: 'success'
          });
        }).catch(error => {
          // console.log(error)
          this.$message({
            message: '账号密码输入有误',
            type: 'error'
          });
        });
      }
      if (this.loginType === 2) {
        // console.log(this.uuid)
        mobilelogin({mobile: this.phone, captcha: this.captcha, uuid: this.uuid}).then(res => {
          localStorage.setItem("userinfo", JSON.stringify(res.data.data.userinfo))
          // console.log(JSON.parse(localStorage.getItem("userinfo")))
          this.nowUser = JSON.parse(localStorage.getItem("userinfo"))
          this.$router.push("/login")
          this.$message({
            message: '登录成功',
            type: 'success'
          });
        }).catch(error => {
          this.$message({
            message: '手机号或验证码输入有误',
            type: 'error'
          });
        });
      }

    },
    getVerificationCode() {
      // 调用API获取验证码的逻辑
      const regex = /^1[3-9]\d{9}$/;
      if (regex.test(this.phone)) {
        send({event: "mobilelogin", mobile: this.phone}).then(res => {
          if (res.data.code === 1) {
            this.$message({
              message: '验证码已发送到您的手机',
              type: 'success'
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
          // console.log(res)
        })
        // 开始倒计时
        this.startCountdown(60); // 假设倒计时60秒
      } else {
        this.$message({
          message: '您输入的手机号格式有误',
          type: 'error'
        });
      }

    },
    startCountdown(seconds) {
      this.countdown = seconds;
      let intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    },
    handleOpen() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped>
.goFirst {
  width: 100px;
  margin-top: 10px;
}

.agreement {
  margin-top: 25px;
  color: #dddddd;
  text-align: center;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    border-bottom: 1px dashed hsl(0, 1%, 72%);
    cursor: pointer;
    text-decoration: none;
    color: #dddddd;
  }
}
.other {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bdbaba;
  height: 60px;
  span:nth-child(1) {
    margin-right: 10px;
    width: 50px;
    height: 1px;
    display: inline-block;
    background: linear-gradient(270deg, rgba(189, 186, 186, 1), rgba(189, 186, 186, 0));
  }
  span:nth-child(2) {
    margin-left: 10px;
    width: 50px;
    height: 1px;
    display: inline-block;
    background: linear-gradient(90deg, rgba(189, 186, 186, 1), rgba(189, 186, 186, 0));
  }
}
.top-div {
  position: absolute;
  z-index: 20;
  top: 0;
  width: 100%;
  display: flex;
  height: 80px;
}

.top-div-item {
  width: 50%;
  text-align: center;
  height: 80px;
  font-size: 20px;
  line-height: 60px;
}

.book-logo {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.book-font {
  height: 25px;
  width: 50px;
  margin-right: 15px;
}

.please_login {
  position: absolute;
  z-index: 20;
  top: 80px;
  font-weight: bold;
  font-size: 32px;
  color: #1D2087;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.forget_PSW {
  text-align: right;
  margin-right: 18%;
  font-size: 13px;
  cursor: pointer;
  color: #2198cb
}

.login_main {
  width: 300px;
  height: 300px;
  top: 90px;
  left: 25px;
  position: absolute;
}

.login_main_title {
  top: 330px;
  width: 85%;
  left: 30px;
  color: white;
  font-size: 30px;
  /*font-weight: bold;*/
  position: absolute;
}

.login_main_tip {
  top: 350px;
  left: 50px;
  color: white;
  font-size: 18px;
  /*font-weight: bold;*/
  position: absolute;
}

.login {
  width: 30%;
  height: 42px;
  margin-left: 5%;
  background: #6598FF;
  border-radius: 8px 8px 8px 8px;
  color: white;
  margin-top: 10px;
}

.input {
  width: 59%;
  box-sizing: border-box;
  margin-left: 5%;
  height: 35px;
  margin-top: 25px;
  border-radius: 0px 0px 0px 0px;
  border: 2px solid;
  background-color: white;
  border-image: linear-gradient(180deg, rgba(87, 124, 255, 1), rgba(66, 154, 236, 1)) 2 2;
}

.input:focus {
  outline: none;
  border-radius: 0px 0px 0px 0px;
  border: 2px solid;
  background-color: white;
  border-image: linear-gradient(180deg, rgba(87, 124, 255, 1), rgba(66, 154, 236, 1)) 2 2;
}

.align {
  text-align: center;
}

.dialog-wrapper {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.login-bg {
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.dialog {
  z-index: 20;
  top: 200px;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.dialog-content {
  width: 100%;
  height: 25vh;
  margin-top: 7vh;
}

</style>
