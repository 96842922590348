<template>
  <div class="total_alert" v-if="show_num" @click="showNumChange()">
    <div class="total_alert_zhe"></div>
    <p class="p_num">＋积分10000</p>
    <img class="num_bg" src="../assets/num_bg.png" alt="">
    <img class="num_del" src="../assets/num_del.png" alt="">
    <img class="num_logo" src="../assets/num_logo.png" alt="">
  </div>
</template>

<script>
import {set_user_flag} from "@/api/1";

export default {
  name: "num_alert",
  data() {
    return {
      show_num: false
    };
  },
  created() {
    if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
      // console.log(JSON.parse(localStorage.getItem("userinfo")))
      if (JSON.parse(localStorage.getItem("userinfo")).flag1===false){
        this.show_num=true
      }
    }
  },
  methods: {
    showNumChange() {
      set_user_flag({name:"flag1",value:1}).then(res => {
        // console.log(res)
        let user = JSON.parse(localStorage.getItem("userinfo"))
        user.flag1=true
        this.show_num=false
        localStorage.setItem("userinfo",JSON.stringify(user))
        this.$message.success("您已成功领取新人积分")
        this.$parent.topsReStart()
      })
    }
  }
}
</script>

<style scoped>
.total_alert {

}

.total_alert_zhe {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
}

.num_bg {
  cursor: pointer;
  position: fixed;
  z-index: 101;
  top: 350px;
  left: 50%;
  width: 420px;
  height: 280px;
  transform: translateX(-50%);
}

.num_logo {
  position: fixed;
  z-index: 102;
  width: 200px;
  left: 50%;
  top: 270px;
  transform: translateX(-50%);
}

.num_del {
  cursor: pointer;
  width: 40px;
  height: 40px;
  top: 650px;
  left: 50%;
  position: fixed;
  z-index: 101;
  transform: translateX(-50%);
}

.p_num {
  color: #6b81fe;
  cursor: pointer;
  position: fixed;
  z-index: 103;
  top: 480px;
  text-align: center;
  font-size: 22px;
  font-weight: bolder;
  left: 50%;
  width: 420px;
  height: 280px;
  transform: translateX(-50%);
}
</style>