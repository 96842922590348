<template>
  <div style="background: linear-gradient( 180deg, #D5EBFF 0%, #F0F7FF 15%, #FAFCFF 22%, #FAFCFF 100%);;min-height: 100vh;">
    <Num_alert></Num_alert>
    <div class="main">
      <p class="main-title">文本<span style="color: #4b6ffd">检测</span></p>
      <div class="textareaBox">
        <textarea class="custom-scrollbar"
            placeholder="请在此处输入需检测的文本"
            @input="descInput"
            v-model="orders"
        ></textarea>
<!--        <span class="numbers">已输入{{ orders.length }}个字</span>-->
      </div>
      <div style="margin-left: 5%;width: 90%;display: flex;justify-content: space-between;margin-top: 10px">
        <div>
          <span>消耗{{ numbers }}积分</span>
          <el-tooltip class="item" effect="dark" content="1积分=1字" placement="top">
            <img style="height: 22px;width: 22px;margin-left: 10px;margin-right: 10px;position: relative;top: 5px" src="../../assets/ask.png" alt="">
          </el-tooltip>
          <span>已输入{{ numbers }}个字</span>
        </div>
        <div v-if="commitPower" @click="submit" class="main-button">提交</div>
        <div v-else class="main-button">提交</div>
      </div>

    </div>
    <TelFeet :click="0"></TelFeet>

  </div>
</template>

<script>
import top from "@/components/Top.vue";
import {analyzeconfirm, order_status, quick, reduceUserWordCount} from "@/api/1";
import Num_alert from "@/components/num_alert.vue";
import TelFeet from "@/components/tel_feet.vue";
export default {
  components: {Num_alert, top,TelFeet},
  data() {
    return {
      click: 0,
      orders: '',
      numbers: 0,
      nowUser: {},
      commitPower:true
    };
  },
  created() {
    // console.log(this.isMobile())
    if( this.isMobile() ){
      this.$router.push("/loginTel")
    }else {
      this.$router.push("/login")
    }
    if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
      this.nowUser=JSON.parse(localStorage.getItem("userinfo"))
    } else {
      setTimeout(() => {
        this.$refs.tops.openLogin()
      }, 1000);
    }
  },

  methods: {
    //该方法用于判断是否进入手机端
    isMobile(){
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
          return true; // 是移动设备
        }
      }
      return false; // 是PC设备
    },
    topsReStart(){
      this.$refs.tops.reLoad()
    },
    async submit() {
      const that = this
      if (that.numbers>100000){
        const confirmResult = await that.$confirm("提交失败，" + "提交单个文本字数不可超过10万字", '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          closeOnClickModal: false,
          showClose: false,
          type: 'error'
        }).catch(err => err)
        /*如果用户确认打印confirm,如果用户取消显示cancel*/
        if (confirmResult === 'confirm') {

        }
      }else {
        this.commitPower=false
        that.submitCommit()
      }
    },
    submitCommit() {
      const that = this
      order_status().then(async res => {
        if (res.data.data.remain_word_count > that.numbers) {
          quick({content: that.orders}).then(async response => {
            // console.log(response)
            if (response.data.code === 1) {
              const confirmResult = await that.$confirm('已成功上传', '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                closeOnClickModal: false,
                showClose: false,
                // cancelButtonText: '取消',
                type: 'success'
              }).catch(err => err)
              /*如果用户确认打印confirm,如果用户取消显示cancel*/
              if (confirmResult === 'confirm') {
                that.$message.success("已提交在线检测")
                reduceUserWordCount({type: 1, tokens: that.numbers}).then(res => {
                  // console.log(res)
                })
                setTimeout(() => {
                  that.$router.push("/category")
                }, 1000);
              }
            } else {
              const confirmResult = await that.$confirm("提交失败，失败原因：" + response.data.msg, '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                closeOnClickModal: false,
                showClose: false,
                customClass: 'box-logout',
                type: 'error'
              }).catch(err => err)
              /*如果用户确认打印confirm,如果用户取消显示cancel*/
              if (confirmResult === 'confirm') {

              }
            }
            that.commitPower = true
          });
        } else {
          const confirmResult = await that.$confirm('您的积分不足啦，是否前往补充积分?', '提示', {
            confirmButtonText: '确定',
            closeOnClickModal: false,
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => err)
          /*如果用户确认打印confirm,如果用户取消显示cancel*/
          if (confirmResult !== 'confirm') {
            return
          } else {
            await that.$router.push("/beVip")
          }
        }
      })
    },
    descInput(e) {
      // var txtVal = this.orders.length;
      // this.numbers = txtVal
      const str = this.orders.replace(/\s*/g, '')
      this.numbers=str.length
    }
  },
};
</script>
<style>
.el-message-box {
  width: 39vh;
}
</style>
<style scoped>
/* 为滚动区域添加一个类名，如 .custom-scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* 水平滚动条的宽度 */
  height: 10px; /* 垂直滚动条的高度 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条的颜色 */
  border-radius: 5px; /* 滚动条的圆角 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* 滚动条轨道的颜色 */
}

/* 针对Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* 针对IE */
.custom-scrollbar {
  -ms-overflow-style: thin;
}

.main-button {
  cursor: pointer;
  width: 25vw;
  height: 40px;
  line-height: 40px;
  background: #6598FF;
  color: white;
  border-radius: 12px 12px 12px 12px;
  /*margin-top: 10px;*/
}

.numbers {
  width: 200px;
  min-width: 400px;
  display: block;
  position: absolute;
  font-size: 18px;
  color: #8c939d;
  height: 50px;
  padding-right: 20px;
  line-height: 50px;
  text-align: right;
  right: 40px;
  bottom: 0;
  border-radius: 12px;
  background-color: white;
}

textarea {
  width: 100%;
  border: none;
  resize: none;
  font-size: 18px;
  font-family: Arial;
  box-sizing: border-box;
  height: 97%;
  padding: 10px 25px 70px;
}
.textareaBox{
  position: relative;
  box-shadow: 1px 1px 10px 5px #ECF2FB;
  border-radius: 12px 12px 12px 12px;
  width: 90vw;
  height: 73vh;
  margin-left: 5vw;
  background-color: white;
}

textarea:focus {
  outline: none;
}

.main-title {
  line-height: 70px;
  padding: 0;
  margin: 0;
  font-size: 25px;
  font-weight: bolder;
  color: rgb(50, 50, 96);
}

.main {
  padding-top: 10px;
  text-align: center;
  /*min-height: 100vh;*/
  width: 100%;
}
</style>