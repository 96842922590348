<template>
  <div class="total">
    <p class="smallItem_1_p1">企业级客户定制</p>
    <p class="smallItem_1_p2">若当前会员等级，无法满足您的日常消耗量，请联系商务购买专属账号，获取更多超值优惠。</p>
    <img class="smallItem_1_img2" src="../../../assets/ewm2.png" alt="">
    <p class="smallItem_1_p3">打开微信扫一扫联系平台客户经理</p>
  </div>
</template>

<script>
export default {
  name: "qyjkh"
}
</script>

<style scoped>
.total {
  padding-top: 8vh;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #D5EBFF 0%, #F0F7FF 15%, #FAFCFF 22%, #FAFCFF 100%);
}

.smallItem_1_p1 {
  width: 100%;
  margin: 0;
  line-height: 10vh;
  text-align: center;
  font-size: 28px;
  color: #2651ba;
  font-weight: bolder;
}

.smallItem_1_p3 {
  width: 100%;
  margin: 0;
  font-weight: bolder;
  line-height: 10vh;
  text-align: center;
}

.smallItem_1_p2 {
  width: 88vw;
  margin-left: 6vw;
  color: #9cabd6;
}

.smallItem_1_img2 {
  width: 40vw;
  margin-left: 30vw;
  height: 40vw;
  margin-top: 10vw;
}
</style>