<template>
  <div class="modal" v-if="show">
    <div class="modal-content" @click="show=false">
    </div>

    <div class="modal-body">
      <div class="modal-header">
        <p class="modal-title">{{ title }}</p>
      </div>
      <slot>
        <el-main>
<!--          :model="ruleForm" :rules="rules"-->
          <el-form ref="ruleForm" label-width="60px" class="demo-ruleForm">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="ruleForm.phone" placeholder="请输入手机号" size=""
                        maxlength="11"></el-input>
            </el-form-item>

            <el-form-item label="验证码" prop="code" style="position: relative">
              <el-input v-model="ruleForm.code" auto-complete="off" placeholder="请输入验证码" size=""
                        maxlength="4"
                        @keyup.enter.native="submitForm('ruleForm')"></el-input>
              <div class="login-code">
                <!--验证码组件-->
                <el-button @click="getCode()" :class="[getCodeBtnDisable?'disabled-style':'disabled-style class_A']"
                           :disabled="getCodeBtnDisable">{{ codeBtnWord }}
                </el-button>
              </div>
            </el-form-item>
          </el-form>
          <el-button class="primaryBtn" type="primary" @click="submitForm('ruleForm')">
            {{submitState===1?"下一步":"确认换绑"}}
          </el-button>
        </el-main>

      </slot>
    </div>
  </div>
</template>
<script>
import {changemobile, check_send, mobilelogin, send} from "@/api/1";

export default {
  name: 'Modal',
  data() {
    return {
      show:false,
      submitState:1,
      ruleForm: {
        phone: '',
        code: '',
      },
      title:"原手机号验证",
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      waitTime: 0, // 获取验证码按钮失效时间
      // 校验
      rules: {
        phone: [
          {required: true, message: '请输入手机号', trigger: 'change'},
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'change' }
        ],
        code: [
          {required: true, message: '请输入验证密码', trigger: 'change'}
        ]
      }
    };
  },
  //计算属性computed
  computed: {
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime === 0) {
          if (this.ruleForm.phone) {
            return false
          }
          return true
        }
        return true
      },
    },

  },
  methods: {
    changeTelShow(){
      this.show=true
    },
    getCode() {
      let that = this
      const regex = /^1[3-9]\d{9}$/; // 中国大陆手机号正则，以1开头，第二位是3-9，接下来是9位数字
      if((this.submitState===1&&this.ruleForm.phone===JSON.parse(localStorage.getItem("userinfo")).mobile)||(this.submitState===2&&regex.test(this.ruleForm.phone))){
        that.waitTime=60
        let event
        if (this.submitState===1){
          event='changemobile_chk'
        }else {
          event='changemobile'
        }
        // 调用获取短信验证码接口
        send({event: event, mobile: that.ruleForm.phone}).then(res => {
          if (res.data.code === 1) {
            this.$message({
              message: '验证码已成功发送',
              type: 'success'
            });
            // 因为下面用到了定时器，需要保存this指向
            that.getCodeBtnDisable = true
            this.codeBtnWord = `${this.waitTime}s 后重新获取`
            let timer = setInterval(function () {
              if (that.waitTime > 1) {
                that.waitTime--
                that.codeBtnWord = `${that.waitTime}s 后重新获取`
              } else {
                clearInterval(timer)
                that.codeBtnWord = '获取验证码'
                that.getCodeBtnDisable = false
                that.waitTime = 0
              }
            }, 1000)
          } else {
            that.getCodeBtnDisable = false
            that.waitTime = 0
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
          // console.log(res)
        })
      }else {
        if(this.submitState===1){
          this.$message.error("您输入的电话号码非本账号手机号码")
        }
        if(this.submitState===2){
          this.$message.error("您输入的电话号码格式有误")
        }
      }
    },
    submitForm(formName) {
      const that = this
      if (that.submitState===1){
        check_send({event: "changemobile_chk", mobile: that.ruleForm.phone,captcha: that.ruleForm.code}).then(res => {
          if(res.data.code===1){
            that.$message.success("核验通过，请输入新的手机号码")
            that.title="更改绑定"
            that.ruleForm.phone=""
            that.ruleForm.code=""
            that.submitState=2
            that.getCodeBtnDisable = false
            that.waitTime = 0
          }else {
            that.$message.error(res.data.msg)
          }
        })
      }else {
        changemobile({mobile: that.ruleForm.phone,captcha: that.ruleForm.code}).then(res => {
          if(res.data.code===1){
            that.$message.success("您已成功换绑手机号码，请重新登录")
            setTimeout(() => {
              localStorage.setItem("userinfo",null)
              that.$router.push("/")
            }, 1000);
          }else {
            that.$message.error(res.data.msg)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.disabled-style{
  position:absolute;
  top: 20%;
  height: 60%;
  line-height: 0;
  right: 20px;
}
.class_A{
  color: white;
  background-color: #7BC8E4;
}
.primaryBtn{
    width: 40%;
  margin-left: 30%;
  margin-top: 30px;
  background: linear-gradient( 128deg, #5FACFF 0%, #6C81FF 100%);
}
.modal-title{
  text-align: center;
  width: 100%;
  font-size: 22px;
  font-weight: bolder;
}
.modal {
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.modal-body{
  width: 90vw;
  left: 5vw;
  height: 330px;
  position: absolute;
  top: 250px;
  background-color: white;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  font-size: 24px;
  font-weight: bold;
  color: #aaaaaa;
}

</style>